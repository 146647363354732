import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
/* import MyPage from './myPageComponent';
import Loading from './Loading';
import ErrorPage from './ErrorPage'; */
export const adalConfig = {
  tenant: "b5e244bd-c492-495b-8b10-61bfd453e423",
  clientId: "1d99de8b-c638-4b70-a135-762a4806e025",
  endpoints: {
    api: "1d99de8b-c638-4b70-a135-762a4806e025",
  },
  apiUrl: "https://servicios-de-valoracion-alb.apps.bancolombia.com",
  cacheLocation: "localStorage",
};
/* const MyProtectedPage = 
withAdalLoginApi(MyPage, () => <Loading />, (error) => <ErrorPage error={error}/>);
<Route 
   path="/onlyLoggedUsers"
   render={ ()=> <MyProtectedPage /> } 
/> */
export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);