import React from "react";
import { Button } from "react-bootstrap";
import XLSX from "xlsx";

function ExportData(data1,data2,data3)
{
    let filename='reports.xlsx';
    var ws = XLSX.utils.json_to_sheet(data1);
    var wss = XLSX.utils.json_to_sheet(data2);
    var wsss = XLSX.utils.json_to_sheet(data3);

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Controles");
    XLSX.utils.book_append_sheet(wb, wss, "Riesgos");
    XLSX.utils.book_append_sheet(wb, wsss, "Riesgo_Control");

    XLSX.writeFile(wb,filename);
 }


export default function Report(props) {
  const { eroData } = props;
  const control_data = eroData[0].control;
  const riesgo_data = eroData[0].riesgo;
  const riesgo_control_data = eroData[0].riesgo_control;

  /*
INTENTANDO GENERAR ESTILOS AL EXCEL, NOTAMOS QUE LA LIBRERÍA NO LO PERMITE EN MODO data, debe ser dataSet
  const riesgo_data = [
    {
        columns: [
          {title: "Id Proceso", width: {wpx: 80}}
        ],
        data: [
          {value : eroData[0].riesgo['idproceso'], style: {font: {sz: "24", bold: true}}}
        
        ]


    }



  ];
*/
  return (
    <div> 
          <Button 
                onClick={(event) => {ExportData(control_data,riesgo_data,riesgo_control_data)}}
          >
                Presiona para descargar resumen
          </Button>
    </div>
  );
}
