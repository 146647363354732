import React, { useState, useMemo, useCallback } from 'react';
import Home from './home.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UsuarioContext } from './Context/UsuarioContext';
import { RecuperacionesContext } from './Context/RecuperacionesContext.js';

function App() {
  const [dataUsuario, setDataUsuario] = useState(null);
  const [dataRecuperaciones, setDataRecuperaciones] = useState(null);
  const [saveSelected, setSaveSelected] = useState(null);
  const [localRisk, setLocalRisk] = useState(null);
  const [editTextArea, setEditTextArea] = useState({
    id: '',
    text: '',
  });

  const [saveReceivedDoc, setReceivedDoc] = useState({});
  const [bodyEdit, setBodyEdit] = useState(null);
  const [bodyFound, setBodyFound] = useState(null);
  const [infoEvaluation, setInfoEvaluation] = useState(null);
  const [saveId, setSaveId] = useState(null);

  const providerUser = useMemo(
    () => ({ dataUsuario, setDataUsuario }),
    [dataUsuario, setDataUsuario]
  );

  const updatedSaveSelected = useCallback(async (selected) => {
    setSaveSelected(selected);
  }, []);

  // const providerRecuperaciones = useMemo(
  //   () => ({ dataRecuperaciones, setDataRecuperaciones }),
  //   [dataRecuperaciones, setDataRecuperaciones]
  // );

  const updatedNewTextArea = useCallback(async (id, redaccion) => {
    setEditTextArea({
      id: id,
      text: redaccion,
    });
  }, []);

  const receivedInfoDocument = useCallback(async (bussines, company) => {
    setReceivedDoc({
      tipo_elemento: bussines,
      compania: company,
    });
  }, []);

  const updatedBodyFound = useCallback(async (found) => {
    setBodyFound(found);
  }, []);

  const updatedBodyEdit = useCallback(async (edit) => {
    setBodyEdit(edit);
  }, []);

  const receivedIdEvaluation = useCallback(async (id) => {
    setSaveId(id);
  }, []);

  return (
    <>
      <UsuarioContext.Provider value={providerUser}>
        <RecuperacionesContext.Provider
          value={{
            dataRecuperaciones,
            setDataRecuperaciones,
            saveSelected,
            localRisk,
            setLocalRisk,
            updatedSaveSelected,
            updatedNewTextArea,
            receivedInfoDocument,
            saveReceivedDoc,
            bodyEdit,
            updatedBodyEdit,
            bodyFound,
            updatedBodyFound,
            editTextArea,
            setEditTextArea,
            infoEvaluation,
            setInfoEvaluation,
            saveId,
            receivedIdEvaluation,
            setBodyEdit, 
            setBodyFound
          }}
        >
          <Home key="homeReact1" />
        </RecuperacionesContext.Provider>
      </UsuarioContext.Provider>
    </>
  );
}

export default App;
