import { useForm } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useModifyEvaluation } from '../hooks/useModifyEvaluation';

import InfoIcon from '@mui/icons-material/Info';
import { useContext } from 'react';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';

export const ModificarEvaluacion = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { receivedIdEvaluation } = useContext(RecuperacionesContext);
  const {
    loadingEvaluation,
    handleModifyIdEvaluation,
    loadingIdEvaluation,
  } = useModifyEvaluation();
  const router = useHistory();

  const handleModify = async () => {
    try {
      await handleModifyIdEvaluation();
    } catch (error) {
      throw new Error(error);
    } finally {
      router.push('/MostrarEvaluacion');
    }
  };

  return (
    <>
      <section style={{ textAlign: 'center' }}>
        <h1
          className="titulo_resumen"
          style={{
            fontSize: '2.25rem',
            lineHeight: '2.5rem',
            textAlign: 'center',
          }}
        >
          Información para actualizar la evaluación
          <br />
        </h1>
      </section>
      {loadingEvaluation ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={handleSubmit(handleModify)}>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '7rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '80%',
              }}
            >
              <label
                className="titulo_resumen"
                style={{
                  fontSize: '1rem',
                  lineHeight: '1rem',
                  textAlign: 'center',
                }}
                htmlFor="evaluacion"
              >
                ID de la evaluación
              </label>
              <input
                {...register('evaluacion', {
                  required: 'El ID es requerido',
                })}
                className="form-control text-left texto"
                style={{ padding: '1.5rem' }}
                type="number"
                onChange={(e) => receivedIdEvaluation(e.target.value)}
              />
              {errors?.evaluacion && (
                <p style={{ color: 'red' }}>
                  <InfoIcon />
                  {errors?.evaluacion?.message}
                </p>
              )}
            </div>
          </section>

          <section
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
              alignItems: 'center',
              paddingTop: '5rem',
            }}
          >
            <button
              onClick={() => {
                window.history.back();
              }}
              type="button"
              className="btn botonNegativo"
            >
              Cancelar
            </button>
            {loadingIdEvaluation ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <button type="submit" className="botonPositivo btn btn-primary">
                Actualizar Evaluacion
              </button>
            )}
          </section>
        </form>
      )}
    </>
  );
};
