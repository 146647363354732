import React, { useState, useEffect, useContext } from "react";

import { Button, Row, Col, Form, Container, Modal } from "react-bootstrap";

import { useFormContext } from "react-hook-form";

import AADService from "../../../auth/authFunctions";

import axios from "axios";
import Loader from "react-loader-spinner";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import { elementType } from "prop-types";
import { IndeterminateCheckBoxRounded } from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function ModalAsociarSegmento(props) {
  const serviceAAD = new AADService();
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [buscando, setBuscando] = React.useState("");
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const { getValues } = useFormContext();

  useEffect(() => {
    setLoadingData(true);
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "/segmentos", {
          headers: {
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        })
        .then((response) => {
          let data = response.data;

          let [compania, factor] = getValues(["compania", "factor"]);

          let list = data.filter(
            (item) =>
              item.compania === compania.label &&
              item.factor === factor.label &&
              item.cluster !== null
          );



          if (Array.isArray(list) && !list.length && props.show) {
            setData([]);
            alert(
              "No hay registros para las opciones seleccionadas (Compañia, Factor)."
            );
            props.onHide();
          } else {
            let filteredlist = list.filter(
              (element) =>
                !props.dataSegmentos.some(
                  (item) => item.idsegmentos === element.idsegmentos
                )
            );

            const checkList = filteredlist.map((obj) => ({
              ...obj,
              checked: false,
            }));

            setData(checkList);
            setData(checkList);
            setDataBusqueda(checkList);

            setLoadingData(false);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, [props.show]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (index) => {
    if (dataBusqueda[index].checked === true) {
      dataBusqueda[index].checked = false;
      setSelected(
        selected.filter((item) => item !== dataBusqueda[index].idsegmentos)
      );
    } else {
      dataBusqueda[index].checked = true;
      setSelected([...selected, dataBusqueda[index].idsegmentos]);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Asociar = () => {
    if (selected.length > 0) {
      const selectedData = dataBusqueda.filter((segmento) =>
        selected.some((item) => item === segmento.idsegmentos)
      );


      if (selectedData.some((data) => data.estado === 0)) {
        alert("No se pueden asociar elementos inactivos");
      } else {
        props.setDataSegmentos([...props.dataSegmentos, ...selectedData]);
      }

      setSelected([]);
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Col sm={8} xs={12}>
          <Modal.Title id="contained-modal-title-vcenter">
            Asociar Segmento
          </Modal.Title>
        </Col>
        <Col sm={2} xs={12}>
          <button type="button" className="btn botonPositivo" onClick={Asociar}>
            Asociar
          </button>
        </Col>

        <Col sm={2} xs={12}>
          <button
            type="button"
            className="btn botonNegativo"
            onClick={props.onHide}
          >
            Cancelar
          </button>
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container fluid>
          <Row className="mb-3 mt-3"></Row>

          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell align="left">ID Segmento</StyledTableCell>
                    <StyledTableCell align="left">Compañia</StyledTableCell>

                    <StyledTableCell align="left">Factor</StyledTableCell>

                    <StyledTableCell align="left">
                      Segmento a priori
                    </StyledTableCell>

                    <StyledTableCell align="left">Cluster</StyledTableCell>

                    <StyledTableCell align="left">
                      Descripción Cluster
                    </StyledTableCell>

                    <StyledTableCell align="left">Estado</StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataBusqueda.map((row, index) => {
                    return (
                      <StyledTableRow
                        key={row.idsegmentos}
                        hover
                        onClick={(event) => handleClick(index)}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={row.checked} />
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          {row.idsegmentos ? row.idsegmentos : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.compania ? row.compania : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.factor ? row.factor : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.segmento_a_priori ? row.segmento_a_priori : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.cluster ? row.cluster : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.descripcion ? row.descripcion : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.estado == 1 ? "Activo" : "Inactivo"}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
