import { useState } from 'react';

export const useHandlers = () => {
  const [switching, setSwitching] = useState(1);
  const [changeSwitch, setChangeSwitch] = useState(null);
  const [textArea, setTextArea] = useState({});
  const [changeText, setChangeText] = useState(null);

  const handleChange = (e, id) => {
    const newChange = e.target.value;
    setTextArea((prevTextArea) => ({
      ...prevTextArea,
      [id]: newChange,
    }));
    setChangeText(id);
  };

  const handleSwitch = (id) => {
    setSwitching((prevSwitching) => {
      const newValue = prevSwitching[id] ? 0 : 1;
      const allValues = { ...prevSwitching, [id]: newValue };
      return allValues;
    });
    setChangeSwitch(id);
  };

  return {
    handleChange,
    handleSwitch,
    switching,
    textArea,
    changeSwitch,
    changeText,
    setChangeSwitch,
    setChangeText,
    setTextArea,
    setSwitching,
  };
};
