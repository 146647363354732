import React, { useState, useEffect, useContext } from "react";

import { Button, Row, Col, Form, Container, Modal } from "react-bootstrap";

import AADService from "../../../auth/authFunctions";

import axios from "axios";
import Loader from "react-loader-spinner";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import { elementType } from "prop-types";
import { useCallback } from "react";
import { DataObject } from "@mui/icons-material";
import { useMemo } from "react";
import {useFormContext} from "react-hook-form";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function ModalAsociarProveedor(props) {
  const serviceAAD = new AADService();
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { setValue } = useFormContext();
  const [buscando, setBuscando] = React.useState("");
  const [dataBusqueda, setDataBusqueda] = React.useState([]);


  function buscar(e) {
    setBuscando(e.target.value);

    const texto = e.target.value.toLowerCase();

    const lowCaseArray = data.filter(
      (item) =>
        String(item.Nombre_Proveedor).toLowerCase().includes(texto) ||
        String(item.ID_SAP).toLowerCase().includes(texto)

    );
    console.log('lowCaseArray: ', lowCaseArray)
    setDataBusqueda(lowCaseArray);
  }


  useEffect(() => {
    console.log("props",props)
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "/maestros_ro/proveedor/", {
          headers: {
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        })
        .then((response) => {
          let data = response.data;

          let filteredlist = data.filter(
            (element) =>
              !props.dataProveedor?.some(
                (item) => item.ID_SAP === element.ID_SAP
              )
          );

          const checkList = filteredlist.map((obj) => ({
            ...obj,
            checked: false,
          }));


          setData(checkList);
          setDataBusqueda(checkList);

        });
    } catch (error) {
      console.error(error);
    }
  }, [props.show]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      props.GetAnexoProveedor(newSelected[0]);
    } else {
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Asociar = () => {
    let proveedor = data.filter((item) => item.ID_SAP === selected[0]);

    console.log("proveedor",proveedor)

    let dataArray = props.dataProveedor;
    let newList = data.filter((item) => item.ID_SAP !== selected[0]);

    setData(newList);
    console.log("newList",newList)
    setValue("nombre_de_fuente", proveedor[0].Nombre_Proveedor);
    setValue("idproveedor", proveedor[0].ID_SAP);

    dataArray?.push(proveedor[0]);
    props?.setDataProveedor(dataArray);
    props?.onHide();
    setSelected([]);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Col sm={3} xs={12}>
          <Modal.Title id="contained-modal-title-vcenter">
            Asociar Proveedor
          </Modal.Title>
        </Col>
        <Col sm={4} xs={12}>
          <Form>
            <Form.Control
              value={buscando}
              onChange={(e) => buscar(e)}
              type="text"
              placeholder="Buscar"
            />
          </Form>
        </Col>

        <Col sm={2} xs={12}>
          <button type="button" className="btn botonPositivo" onClick={Asociar}>
            Asociar Proveedor
          </button>
        </Col>

        <Col sm={2} xs={12}>
          <button
            type="button"
            className="btn botonNegativo"
            onClick={props.onHide}
          >
            Cancelar
          </button>
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container fluid>
          <Row className="mb-3 mt-3"></Row>

          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell align="left">ID SAP</StyledTableCell>
                    <StyledTableCell align="left">Proveedor</StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataBusqueda
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const isItemSelected = isSelected(row.ID_SAP);
                      return (
                        <StyledTableRow
                          key={row.ID_SAP}
                          hover
                          onClick={(event) => handleClick(event, row.ID_SAP)}
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row.ID_SAP !== null ? row.ID_SAP : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.Nombre_Proveedor !== null ? row.Nombre_Proveedor : null}
                          </StyledTableCell>

                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
