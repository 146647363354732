import React from "react";
import { Button } from "react-bootstrap";
import XLSX from "xlsx";

function ExportData(data1)
{
    let filename='reports.xlsx';
    var ws = XLSX.utils.json_to_sheet(data1);

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Delegados");


    XLSX.writeFile(wb,filename);
 }

export default function Report(props) {
  const { delegatesData } = props;
  const delegates_data = delegatesData[0].delegates;

  return (
    <div> 
          <Button 
                onClick={(event) => {ExportData(delegates_data)}}
          >
                Presiona para descargar resumen
          </Button>
    </div>
  );
}
