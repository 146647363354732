import { useForm } from 'react-hook-form';
import { CircularProgress, Alert } from '@mui/material';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useModifyEvaluation } from '../hooks/useModifyEvaluation';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import InfoIcon from '@mui/icons-material/Info';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const MostrarEvaluacion = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const [localSelected, _] = useLocalStorage('Selected');
  const { infoEvaluation, localRisk, saveId } = useContext(
    RecuperacionesContext
  );
  const { loadingIdEvaluation, errorIdEvaluation } = useModifyEvaluation();
  const [loadingSubmitEvaluation, setLoadingSubmitEvaluation] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const router = useHistory();

  let newIdEvaluation = new Set();
  for (const key in infoEvaluation) {
    const idEvaluation = infoEvaluation[key]?.id_evaluacion;
    newIdEvaluation.add(idEvaluation);
  }

  const handleSubmitEvaluation = async () => {
    const newRisks = Object.values(infoEvaluation)
      .filter((item) => item.id_riesgo === null)
      .map((item) => {
        return {
          id_evaluacion: item.id_evaluacion,
          id_elemento: Number(localSelected) || Number(localRisk[0].idElemento),
          id_riesgo: item.id_riesgo,
          id_categoria_n1_grc: item.id_categoria_n1_grc,
        };
      });

    newRisks.push(...selectedValues);
    const requestBody = newRisks;

    try {
      setLoadingSubmitEvaluation(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/modificar_evaluacion/recibir_id_riesgos_actualizar/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json ;charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
          body: JSON.stringify(requestBody),
        }
      );
    } catch (error) {
      throw new Error(`Error al guardar los riesgos ${error}`);
    } finally {
      setLoadingSubmitEvaluation(false);
      router.push('/CrearIdEvaluacion');
    }
  };

  const handleSelectEvaluation = (e, unique) => {
    const newValue = e.target.value;

    const filteredValues = selectedValues.filter(
      (item) => Number(item.id_riesgo) === Number(newValue)
    );

    setSelectedValues([
      ...filteredValues,
      {
        id_evaluacion: saveId,
        id_elemento: Number(localSelected) || Number(localRisk[0].idElemento),
        id_riesgo: Number(newValue),
        id_categoria_n1_grc: unique?.join(),
      },
    ]);
  };

  return (
    <>
      {errorIdEvaluation ? (
        <p>Ups ese ID no existe</p>
      ) : (
        <>
          <section style={{ textAlign: 'center' }}>
            <h1
              className="titulo_resumen"
              style={{
                fontSize: '2.25rem',
                lineHeight: '2.5rem',
                textAlign: 'center',
              }}
            >
              Información para actualizar la evaluación
              <br />
            </h1>
          </section>
          <section style={{ textAlign: 'center' }}>
            <h1
              style={{
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                paddingTop: '5rem',
                textAlign: 'center',
              }}
            >
              <strong className="titulo_resumen">ID de la evaluacion: </strong>
              {newIdEvaluation}
            </h1>
          </section>
          <form onSubmit={handleSubmit(handleSubmitEvaluation)}>
            {loadingIdEvaluation ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={30} />
              </div>
            ) : (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '2rem',
                  paddingTop: '2rem',
                }}
              >
                {Object.keys(infoEvaluation).map((key, index) => {
                  const titleRisk = key;
                  const nameRisk = infoEvaluation[key]?.nombre_riesgo;

                  let listIdEvaluation;

                  if (infoEvaluation[key]?.length > 1) {
                    listIdEvaluation = infoEvaluation[key];
                  }

                  let unique = [];
                  for (let i = 0; i < listIdEvaluation?.length; i++) {
                    if (
                      !unique.includes(listIdEvaluation[i]?.id_categoria_n1_grc)
                    ) {
                      unique.push(listIdEvaluation[i]?.id_categoria_n1_grc);
                    }
                  }

                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <h1
                        className="titulo_resumen"
                        style={{
                          fontSize: '1rem',
                          lineHeight: '1rem',
                          textAlign: 'center',
                          paddingTop: '1rem',
                        }}
                        htmlFor="riesgo"
                      >
                        Nombre de la evaluacion:
                      </h1>
                      <Alert
                        variant="filled"
                        severity="info"
                        sx={{ fontSize: '1rem', backgroundColor: 'black' }}
                      >
                        {titleRisk}
                      </Alert>
                      <label
                        className="titulo_resumen"
                        style={{
                          fontSize: '1rem',
                          lineHeight: '1rem',
                          textAlign: 'center',
                          paddingTop: '1rem',
                        }}
                        htmlFor="riesgo"
                      >
                        Nombre del riesgo
                      </label>
                      {listIdEvaluation ? (
                        <>
                          <select
                            {...register('riesgo', {
                              required: 'Este campo es requerido',
                            })}
                            required
                            style={{
                              marginBottom: '5rem',
                              borderRadius: '0.5rem',
                              padding: '0.7rem',
                              borderStyle: 'solid',
                            }}
                            onChange={(e) => handleSelectEvaluation(e, unique)}
                          >
                            <option disabled selected>
                              Selecciona un riesgo
                            </option>
                            {listIdEvaluation?.map((item, index) => (
                              <option key={index} value={item?.id_riesgo}>
                                {item?.nombre_riesgo}
                              </option>
                            ))}
                          </select>
                          {errors?.riesgo && (
                            <p style={{ color: 'red' }}>
                              <InfoIcon />
                              {errors?.riesgo?.message}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <select
                            {...register('riesgoNuevo', {
                              required: 'Este campo es requerido',
                            })}
                            style={{
                              marginBottom: '5rem',
                              borderRadius: '0.5rem',
                              padding: '0.7rem',
                              borderStyle: 'solid',
                            }}
                          >
                            <option value={nameRisk}>{nameRisk}</option>
                          </select>
                          {errors?.riesgoNuevo && (
                            <p style={{ color: 'red' }}>
                              <InfoIcon />
                              {errors?.riesgoNuevo?.message}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {loadingSubmitEvaluation ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={30} />
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="botonPositivo btn btn-primary" type="submit">
                  Guardar
                </button>
              </div>
            )}
          </form>
        </>
      )}
    </>
  );
};
