import { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useHandlers } from '../hooks/useHandlers';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import InfoIcon from '@mui/icons-material/Info';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const EditarRedaccion = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { editTextArea } = useContext(RecuperacionesContext);
  const { textArea, handleChange } = useHandlers();
  const [textRubrica, setTextRubrica] = useState('');
  const [radioValues, setRadioValues] = useState(Number);
  const [loadingRubrica, setLoadingRubrica] = useState(false);
  const router = useHistory();


  const handleSubmitRubrica = async () => {
    const requestBody = {
      id: editTextArea?.id,
      id_rubrica: radioValues,
      comentario_rubrica: textRubrica,
      redaccion_analista: textArea[editTextArea?.id] ? textArea[editTextArea?.id] : editTextArea?.text,
    };

    try {
      setLoadingRubrica(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/guardar_rubrica/`,
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoadingRubrica(false);
      router.push('/EditarIdentificador');
    }
  };

  return (
    <>
      <section
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1
          className="titulo_resumen"
          style={{
            fontSize: '2.25rem',
            lineHeight: '2.5rem',
          }}
        >
          Razones del cambio
        </h1>
        <p>Nota: Es un modal al dar click en "editar redaccion"</p>
      </section>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        <h3
          className="client-info"
          style={{
            background: '#3d3b3a',
            borderRadius: '0.5rem',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Selecciona la razon por la cual vas a cambiar la redacción
        </h3>
        {errors?.parcialmente && (
          <p className="titulo_resumen" style={{ color: 'red' }}>
            <InfoIcon /> {errors?.parcialmente?.message}
          </p>
        )}
        <table border="1" bordercolor="#000000">
          <tr>
            <th style={{ padding: '1rem' }}>
              <input
                {...register('parcialmente', {
                  required: 'Este campo es requerido.',
                })}
                onChange={() => setRadioValues(4)}
                type="radio"
              />
            </th>
            <td>
              <h3 className="titulo_resumen">Parcialmente coherente:</h3>
              <p>
                La respuesta es coherente con la clasificación del riesgo y
                contiene información correcta. Sin embargo, se añade información
                falsa, fuera de contexto, irrelevante y/o falta información
                relevante.
              </p>
            </td>
          </tr>
          <tr>
            <th style={{ padding: '1rem' }}>
              <input
                {...register('parcialmente', {
                  required: 'Este campo es requerido.',
                })}
                onChange={() => setRadioValues(3)}
                type="radio"
              />
            </th>
            <td>
              <h3 className="titulo_resumen">Parcialmente incoherente:</h3>
              <p>
                La respuesta es coherente con la clasificación del riesgo, sin
                embargo, en su mayoría contiene imprecisiones respecto a la
                descripción del riesgo.
              </p>
            </td>
          </tr>
          <tr>
            <th style={{ padding: '1rem' }}>
              <input
                {...register('parcialmente', {
                  required: 'Este campo es requerido.',
                })}
                onChange={() => setRadioValues(2)}
                type="radio"
              />
            </th>
            <td>
              <h3 className="titulo_resumen">Incoherente:</h3>
              <p>
                La redacción no está orientada al proceso y/o a la clasificación
                de riesgo, pero si está asociada a riesgo operacional.
              </p>
            </td>
          </tr>
          <tr>
            <th style={{ padding: '1rem' }}>
              <input
                {...register('parcialmente', {
                  required: 'Este campo es requerido.',
                })}
                onChange={() => setRadioValues(1)}
                type="radio"
              />
            </th>
            <td>
              <h3 className="titulo_resumen">Totalmente incoherente:</h3>
              <p>La redacción no está orientada a riesgos operacionales.</p>
            </td>
          </tr>
        </table>
        <form onSubmit={handleSubmit(handleSubmitRubrica)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '2rem',
            }}
          >
            <input
              onChange={(e) => setTextRubrica(e.target.value)}
              className="form-control text-left texto"
              placeholder="Agregar comentarios adicionales"
              style={{ marginBottom: '2rem', padding: '1.5rem' }}
            />
            <h1
              style={{
                fontSize: '2.25rem',
                lineHeight: '2.5rem',
              }}
              className="titulo_resumen"
            >
              Nueva descripcion del riesgo
            </h1>

            <textarea
              {...register('redaccion', {
                required: 'La redacción es requerida.',
              })}
              value={
                textArea === null
                  ? editTextArea?.text
                  : textArea[editTextArea?.id]
              }
              onChange={(e) => handleChange(e, editTextArea?.id)}
              className="form-control text-left texto"
              placeholder="Precargar la descripción vigente (editable)"
              style={{ padding: '1.5rem' }}
            >
              {editTextArea?.text}
            </textarea>
            {errors?.redaccion && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '1rem',
                  paddingTop: '2rem',
                }}
              >
                <p style={{ color: 'red' }}>
                  <InfoIcon />
                  {errors?.redaccion?.message}
                </p>
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '1rem',
            }}
          >
            {loadingRubrica ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '5rem',
                }}
              >
                <CircularProgress size={30} />
              </div>
            ) : (
              <button type="submit" className="botonPositivo btn btn-primary">
                Guardar
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
