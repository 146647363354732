import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const useAPICALL = () => {
  const { bodyFound, bodyEdit } = useContext(RecuperacionesContext)
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingNewRisk, setLoadingNewRisk] = useState(false);
  const [loadingEvaluation, setLoadingEvaluation] = useState(false);

  const router = useHistory();

  const OnNewSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingSubmit(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/guardar_bds_propias_subir_evaluacion_riesgos/`,
        {
          method: 'POST',
          body: JSON.stringify(bodyFound || bodyEdit),
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
    } catch (error) {
      throw new Error(`Error has ocurred in POST ${error}`);
    } finally {
      setLoadingSubmit(false);
      router.push('/CrearIdEvaluacion');
    }

  };

  const OnSubmitCancel = async () => {
    try {
      setLoadingCancel(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/guardar_datos_al_cancelar/`,
        {
          method: 'POST',
          body: JSON.stringify(bodyFound || bodyEdit),
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
    } catch (error) {
      throw new Error(`Error has ocurred in POST ${error}`);
    } finally {
      setLoadingCancel(false);
      router.push('/RiesgosParciales');
    }
  };

  const OnEditRiskSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadingNewRisk(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/guardar_datos_parcialmente/`,
        {
          method: 'POST',
          body: JSON.stringify(bodyFound || bodyEdit),
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      router.push('/RiesgosParciales');
      setLoadingNewRisk(false);
    }
  };

  const OnSaveEvaluation = async (e) => {
    e.preventDefault();
    try {
      setLoadingEvaluation(true);
      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/modificar_evaluacion/guardar_datos/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
          body: JSON.stringify(bodyFound || bodyEdit),
        }
      );
    } catch (error) {
      throw new Error('Ocurrio un error al actualizar la evaluacion');
    } finally {
      setLoadingEvaluation(false);
      router.push('/ModificarEvaluacion');
    }
  };

  return {
    loadingSubmit,
    loadingCancel,
    loadingNewRisk,
    OnNewSubmit,
    OnSubmitCancel,
    OnEditRiskSubmit,
    OnSaveEvaluation,
    loadingEvaluation,
  };
};
