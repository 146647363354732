import axios from "axios";
import AADService from "../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { UsuarioContext } from "../../Context/UsuarioContext";
import { FormInputDate } from "../../form-components/FormInputDate";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

export default function ModalTesting(props) {
  const serviceAAD = new AADService();

  const classes = useStyles();
  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });
  const [ID, setID] = useState(null);

  const [flagListaTipologias, setFlagListaTipologias] = useState(false);
  const [listaTipologias, setListaTipologias] = useState([]);
  const [causasAsociadas, setCausasAsociadas] = useState([]);

  const { dataUsuario } = React.useContext(UsuarioContext);

  const [listaAuditor, setListaAuditor] = useState([]);

  const defaultValues = {
    id: null,
    estado: null,
    fecha: null,
    auditor: null,
    creador: null,
    diseno: null,
    operatividad: null,
    control: null,
    observaciones: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };
    let dataEnviar = {
      fecha: data.fecha,
      auditor: data.auditor?.label,
      usuario_creador: data.creador,
      conclusion_diseno: parseFloat(data.diseno),
      conclusion_operatividad: parseFloat(data.operatividad),
      conclusion_control: parseFloat(data.control),
      observaciones: data.observaciones,
    };

    console.log("Datos a enviar al back", dataEnviar);

    try {
      if (props.isEditing) {
        dataEnviar.idtesting = data.id;
        //dataEnviar.estado = data.estado.value;

        axios
          .put(
            process.env.REACT_APP_API_URL + "/testing/" + data.id,
            dataEnviar,
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              console.log(response);
              alert("Guardado con éxito");
              let index = props.data.findIndex(
                (item) => item.idtesting === data.id
              );
              props.data.splice(index, 1, response.data);
              reset();
              props.onHide();
            }
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + "/testing/", dataEnviar, config)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              console.log(response);
              alert("Guardado con éxito");
              props.data.push(response.data);
              reset();
              props.onHide();
            }
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/4", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/2", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/1", config),
    ];

    setValue("creador", dataUsuario.nombre);

    Promise.all(APIS)
      .then(async ([riskAnalyst, riskManager, administrador]) => {
        const rAnalist = await riskAnalyst.json();

        const rManager = await riskManager.json();

        const admin = await administrador.json();

        let listRiskAnalist = rAnalist.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listRiskManager = rManager.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listAdministrador = admin.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listExpertos = listRiskAnalist.concat(
          listRiskManager,
          listAdministrador
        );

        setListaAuditor(listExpertos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.show]);

  useEffect(() => {
    if (Array.isArray(props.data)) {
      if (props.selected[0] && !!props.isEditing) {
        // axios
        //   .get(process.env.REACT_APP_API_URL +"/testing/" + props.selected[0], {
        //     headers: {
        //       Authorization: "Bearer " + serviceAAD.getToken(),
        //     },
        //   })
        //   .then((response) => {
        //     setCausasAsociadas(response.data);
        //   });

        props.data.forEach((obj) => {
          if (obj.idtesting === props.selected[0]) {
            setValue("id", obj.idtesting);

            setID(obj.idtesting);

            console.log(obj);

            // fecha: data.fecha,
            // auditor: data.auditor?.label,
            // usuario_creador: data.creador,
            // conclusion_diseno: parseFloat(data.diseno),
            // conclusion_operatividad: parseFloat(data.operatividad),
            // conclusion_control: parseFloat(data.control),
            // observaciones: data.observaciones,

            let fecha = new Date(obj.fecha);

            setValue("fecha", fecha);

            setValue("auditor", {
              value: obj.auditor,
              label: obj.auditor,
            });

            setValue("creador", obj.usuario_creador);

            setValue("diseno", obj.conclusion_diseno);
            setValue("operatividad", obj.conclusion_operatividad);
            setValue("control", obj.conclusion_control);

            setValue("observaciones", obj.observaciones);
          }
        });
      } else {
        reset();
      }
    }
  }, [props.isEditing]);

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Testing Auditoría
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {props.isEditing ? (
              <Row className="mb-4">
                <Col sm={2} xs={12}>
                  <label className="forn-label label">ID</label>
                </Col>
                <Col sm={4} xs={12}>
                  <input
                    {...register("id")}
                    disabled={true}
                    type="text"
                    className="form-control text-center texto"
                    placeholder="ID"
                    //value={id}
                  />
                </Col>
                {/* 
                <Col sm={2} xs={12}>
                  <label className="forn-label label">Estado</label>
                </Col>
                <Col sm={4} xs={12}>
                  <Controller
                    control={control}
                    name="estado"
                    render={({ field }) => (
                      <Select
                        components={animatedComponents}
                        placeholder="Estado"
                        options={[
                          { value: 1, label: "Activo" },
                          { value: 0, label: "Inactivo" },
                        ]}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                      />
                    )}
                  />
                </Col> */}
              </Row>
            ) : (
              <></>
            )}

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Fecha*</label>
              </Col>

              <Col sm={4} xs={12}>
                <FormInputDate
                  control={control}
                  name={"fecha"}
                  label={"Fecha"}
                />
                <p className="text-center">{errors.fecha?.message}</p>
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Auditor*</label>
              </Col>

              <Col sm={4} xs={12}>
                <Controller
                  control={control}
                  name="auditor"
                  rules={{ required: "Te faltó completar este campo" }}
                  render={({ field }) => (
                    <Select
                      components={animatedComponents}
                      placeholder="Auditor"
                      options={listaAuditor}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <p className="text-center">{errors.version?.message}</p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Usuario Creador</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("creador", {
                    required: false,
                  })}
                  type="text"
                  disabled={true}
                  className="form-control text-center texto"
                  placeholder="Usuario Creador"
                />
                <p className="text-center">{errors.creador?.message}</p>
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Conclusión Diseño*</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("diseno", {
                    pattern: {
                      value: /^(0(\.\d+)?|1(\.0+)?)$/,
                      message: "Ingrese un número decimal entre 0 y 1",
                    },
                    required: `Te faltó completar este campo`,
                  })}
                  type="text"
                  className="form-control text-center texto"
                  placeholder="Conclusión Diseño"
                />
                <p className="text-center">{errors.diseno?.message}</p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">
                  Conclusión Operatividad*
                </label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("operatividad", {
                    pattern: {
                      value: /^(0(\.\d+)?|1(\.0+)?)$/,
                      message: "Ingrese un número decimal entre 0 y 1",
                    },
                    required: `Te faltó completar este campo`,
                  })}
                  type="text"
                  className="form-control text-center texto"
                  placeholder="Conclusión Operatividad"
                />
                <p className="text-center">{errors.operatividad?.message}</p>
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Conclusión Control*</label>
              </Col>

              <Col sm={4} xs={12}>
                <input
                  {...register("control", {
                    pattern: {
                      value: /^(0(\.\d+)?|1(\.0+)?)$/,
                      message: "Ingrese un número decimal entre 0 y 1",
                    },
                    required: `Te faltó completar este campo`,
                  })}
                  type="text"
                  className="form-control text-center texto"
                  placeholder="Conclusión Control"
                />
                <p className="text-center">{errors.control?.message}</p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Observaciones</label>
              </Col>
              <Col sm={10} xs={12}>
                <textarea
                  {...register("observaciones")}
                  rows={"3"}
                  className="form-control text-center texto"
                  placeholder="Observaciones"
                />
                <p className="text-center">{errors.observaciones?.message}</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonPositivo"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Guardar
          </Button>
          <Button className="botonNegativo" onClick={props.onHide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}
