import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";

export default function ModalUmbral(props) {
  const serviceAAD = new AADService();

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const defaultValues = {
    id: null,
    estado: null,
    limInferior: null,
    limSuperior: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    //Si el limInferior o limSuperior tienen "," se reemplaza por "."
    if (data.limInferior) {
      data.limInferior = data.limInferior.replace(",", ".");
    }
    if (data.limSuperior) {
      data.limSuperior = data.limSuperior.replace(",", ".");
    }


    let dataEnviar = {
      //estado: 1,
      limite_inferior: data.limInferior ? parseFloat(data.limInferior) : null,
      limite_superior: data.limSuperior ? parseFloat(data.limSuperior) : null,
      //lim_extra: data.limSuperior ? data.limSuperior : null,
    };


    try {
      if (!!props.isEditing) {
        dataEnviar.idumbral = data.id;

        axios
          .put(
            process.env.REACT_APP_API_URL + "/umbrales/" + data.id,
            dataEnviar,
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");
              let index = props.data.findIndex(
                (item) => item.idumbral === data.idumbral
              );
              props.data.splice(index, 1, response.data);
              reset();
              props.onHide();
            } else if (response.status >= 300 && response.status < 400) {
              setEstadoPost(4);
            } else if (response.status >= 400 && response.status < 512) {
              setEstadoPost(5);
            }
          }).catch((error) => {
            alert("Error al guardar\n Algunos campos no son válidos por su longitud");
            console.error(error);
          });
      } else {
        axios
          .post(process.env.REACT_APP_API_URL + "/umbrales", dataEnviar, config)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");
              props.data.push(response.data);
              reset();
              props.onHide();
            } else if (response.status >= 300 && response.status < 400) {
              setEstadoPost(4);
            } else if (response.status >= 400 && response.status < 512) {
              setEstadoPost(5);
            }
          }).catch((error) => {
            alert("Error al guardar\n Algunos campos no son válidos por su longitud");
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    if (props.selected[0] && !!props.isEditing) {
      axios
        .get(process.env.REACT_APP_API_URL + "/umbrales/" + props.selected[0], {
          headers: {
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        })
        .then((response) => {
          setValue("id", response.data.idumbral);
          setValue("limInferior", parseFloat(response.data.limite_inferior));
          setValue("limSuperior", parseFloat(response.data.limite_superior));
        });
    } else {
      reset();
    }
  }, [props.isEditing]);

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Umbral</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {!!props.isEditing ? (
              <Row className="mb-4">
                <Col sm={2} xs={12}>
                  <label className="forn-label label">ID</label>
                </Col>
                <Col sm={4} xs={12}>
                  <input
                    {...register("id")}
                    disabled
                    type="text"
                    className="form-control text-center texto"
                    placeholder="ID"
                    //value={id}
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Límite inferior*</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("limInferior", {
                    required: "Te faltó completar este campo",
                  })}
                  type="text"
                  className="form-control text-center texto"
                />
                <p className="text-center">{errors.limInferior?.message}</p>
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Límite superior*</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("limSuperior", {
                    required: "Te faltó completar este campo",
                  })}
                  type="text"
                  className="form-control text-center texto"
                />
                <p className="text-center">{errors.limSuperior?.message}</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonPositivo"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Guardar
          </Button>
          <Button className="botonNegativo" onClick={props.onHide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}
