import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import TableCustom from "../../Components/TableCustom";

export default function VerAplicacion() {
  const [aplicativo, setAplicativo] = useState(null);
  const [opciones, setOpciones] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/aplicativosget/${localStorage.getItem("idactivo")}`
        );
        setAplicativo(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (aplicativo) {
      console.log('aplicativo actualizado', aplicativo);
    }
  }, [aplicativo]);

  const renderInput = (label, value, id) => (
    <Row className="mb-3">
      <Col sm={4} xs={12}>
        <label className="label forn-label">{label}</label>
      </Col>
      <Col sm={8} xs={12}>
        <input
          type="text"
          disabled
          value={value || ""}
          className="form-control text-center texto"
          id={id}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <Row className="mb-3">
        <Col md={12}>
          <h1 className="titulo">Ver información de la aplicación</h1>
        </Col>
      </Row>
      <hr />
      {renderInput("Id Aplicación", aplicativo?.idaplicativo, "IdOpcion")}
      {renderInput(
        "Estado",
        aplicativo?.estado_aplicativo === 1 ? "Activo" : "Inactivo",
        "estadoOC"
      )}
      {renderInput("Tipo activo", aplicativo?.tipo_activo, "tipoActivo")}
      {renderInput("Nombre aplicación", aplicativo?.nombre, "nombreAplicacion")}
      <Row className="mb-3">
        <Col sm={4} xs={12}>
          <label className="label forn-label">Descripción</label>
        </Col>
        <Col sm={8} xs={12}>
          <textarea
            className="form-control text-center"
            placeholder="Descripción de la aplicación"
            value={aplicativo?.descripcion || ""}
            rows="3"
            disabled
            id="Descripcion"
          />
        </Col>
      </Row>
      {renderInput("Usuario Responsable TI", aplicativo?.idposicionresponsableti, "usrRespTI")}
      {renderInput("Usuario Responsable Negocio", aplicativo?.idposicionresponsablenegocio, "usrRespNeg")}
      {renderInput("Nivel Criticidad Ciber", aplicativo?.nivel_criticidad, "criticidad")}
      <Row className="mb-3">
        <Col sm={6} xs={12}></Col>
        <Col sm={3} xs={12}>
          <label className="form-label texto ml-2">BIA &nbsp;</label>
          <input
            disabled
            type="checkbox"
            checked={aplicativo?.bia || false}
          />
        </Col>
        <Col sm={3} xs={12}>
          <label className="form-label texto ml-2">SOX &nbsp;</label>
          <input
            disabled
            type="checkbox"
            checked={aplicativo?.sox || false}
          />
        </Col>
      </Row>
      <TableCustom
        data={opciones}
        nameCol={[
          "ID Opción",
          "Nombre opción técnica",
          "Código opción técnica",
          "Descripción técnica",
          "ID del proceso",
        ]}
        nameRow={["idproceso", "nombre", "compania", "ciclo"]}
        nameId={"idopcion"}
      />
      <Row className="mb-3">
        <Col sm={8} xs={1}></Col>
        <Col sm={3} xs={3}>
          <Link to="Aplicaciones">
            <button type="button" className="btn botonNegativo">
              Volver
            </button>
          </Link>
        </Col>
      </Row>
      <Row className="mb-5 mt-5">
        <br />
      </Row>
    </>
  );
}