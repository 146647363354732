import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

export default function ModalExpertos(props) {
  const serviceAAD = new AADService();

  const classes = useStyles();

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const [listaExpertos, setListaExpertos] = useState([]);

  const [listaCompanias, setListaCompanias] = useState(null);

  const defaultValues = {
    id: null,
    experto: null,
    companias: null,
    estado: "No enviado",
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let arrayOfLabels = [];

    data.companias.forEach((element) => {
      arrayOfLabels.push(element.label);
    });

    let dataEnviar = {
      fecha_corte: props.fechaCorte,
      idusuario: data.experto.value,
      nombre_usuario: data.experto.label,
      email_usuario: data.experto.email,
      compania: arrayOfLabels.toString(),
    };

    let response;

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    try {
      if (!!props.isEditing) {
        response = await axios.put(
          process.env.REACT_APP_API_URL + "/asociar_experto/" + data.experto.value,
          dataEnviar,
          config
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_API_URL + "/asociar_experto/",
          dataEnviar,
          config
        );
      }
    } catch (error) {
      alert(error.response.data.message);
    }

    if (response.status >= 200 && response.status < 300) {
      alert("Guardado con éxito");
      props.ApiExpertos(response.data.fecha_corte);
      reset();
      props.onHide();
    } else if (response.status >= 300 && response.status < 400) {
      setEstadoPost(4);
      alert("Error en el servidor");
    } else if (response.status >= 400 && response.status < 512) {
      setEstadoPost(5);
      alert("Error en el servidor");
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/maestros_ro/compania/", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/4", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/2", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/1", config),
    ];

    Promise.all(APIS)
      .then(async ([companias, riskAnalyst, riskManager, administrador]) => {
        const compania = await companias.json();

        const rAnalist = await riskAnalyst.json();

        const rManager = await riskManager.json();

        const admin = await administrador.json();

        let listCompanias = compania.map(
          ({ idcompania: value, compania: label }) => ({
            value,
            label,
          })
        );

        setListaCompanias(listCompanias);

        let listRiskAnalist = rAnalist.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listRiskManager = rManager.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listAdministrador = admin.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listExpertos = listRiskAnalist.concat(
          listRiskManager,
          listAdministrador
        );

        setListaExpertos(listExpertos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.show]);

  // useEffect(() => {
  //   if (Array.isArray(props.dataTipologias)) {
  //     if (props.selected[0] && !!props.isEditing) {
  //       axios
  //         .get(process.env.REACT_APP_API_URL + "/rxcausatipologias/" + props.selected[0], {
  //           headers: {
  //             Authorization: "Bearer " + serviceAAD.getToken(),
  //           },
  //         })
  //         .then((response) => {
  //           setCausasAsociadas(response.data);
  //         });

  //       props.dataTipologias.forEach((obj) => {
  //         if (obj.idtipologias === props.selected[0]) {
  //           setValue("id", obj.idtipologias);
  //           setID(obj.idtipologias);
  //           setValue("origen", {
  //             value: obj.origen,
  //             label: obj.origen,
  //           });

  //           setValue("idEquivalente", obj.id_equivalente_informe);
  //           setValue("version", obj.version_informe);

  //           if (obj.nivel === 2) {
  //             setFlagListaTipologias(true);
  //           }

  //           console.log(obj.nivel);

  //           setValue("nivel", { value: obj.nivel, label: obj.nivel });
  //           setValue("tipologiaN1", {
  //             value: obj.tipologia_n1,
  //             label: obj.tipologia_n1,
  //           });

  //           setValue("tipologia", obj.tipologia);

  //           setValue("descripcion", obj.descripcion);

  //           if (obj.estado == 1) {
  //             setValue("estado", { value: obj.estado, label: "Activo" });
  //           } else {
  //             setValue("estado", { value: obj.estado, label: "Inactivo" });
  //           }
  //         }
  //       });
  //     } else {
  //       reset();
  //     }
  //   }
  // }, [props.isEditing]);

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Asociar Expertos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {!!props.isEditing ? <></> : <></>}

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Experto</label>
              </Col>
              <Col sm={4} xs={12}>
                <Controller
                  control={control}
                  name="experto"
                  rules={{
                    required: "Te faltó completar este campo",
                  }}
                  render={({ field }) => (
                    <Select
                      components={animatedComponents}
                      placeholder="Seleccione el Experto"
                      options={listaExpertos}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
                <p className="text-center">{errors.experto?.message}</p>
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Compañias</label>
              </Col>
              <Col sm={4} xs={12}>
                <Controller
                  control={control}
                  name="companias"
                  rules={{
                    required: "Te faltó completar este campo",
                  }}
                  render={({ field }) => (
                    <Select
                      isMulti
                      components={animatedComponents}
                      placeholder="Seleccione las Compañias"
                      options={listaCompanias}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
                <p className="text-center">{errors.companias?.message}</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonPositivo"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Guardar
          </Button>
          <Button className="botonNegativo" onClick={props.onHide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}
