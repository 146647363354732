import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { forwardRef } from "react";
import MaterialTable from "material-table";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const animatedComponents = makeAnimated();

export default function ModalIndicadores(props) {
  const serviceAAD = new AADService();

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });
  const [ID, setID] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [dataGrid, setDataGrid] = useState([]);

  const [flagUmbral, setFlagUmbral] = useState(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => (
      <button type="button" className="btn botonPositivo2">
        <AddCircleOutlineIcon {...props} ref={ref} />
      </button>
    )),
    Check: forwardRef((props, ref) => (
      <button type="button" className="btn botonPositivo2">
        <Check {...props} ref={ref} />
      </button>
    )),
    Clear: forwardRef((props, ref) => (
      <button type="button" className="btn botonNegativo2">
        <Clear {...props} ref={ref} />
      </button>
    )),
    Delete: forwardRef((props, ref) => (
      <button type="button" className="btn botonNegativo2">
        <DeleteForeverIcon {...props} ref={ref} />
      </button>
    )),
    Edit: forwardRef((props, ref) => (
      <button type="button" className="btn botonGeneral2">
        <Edit {...props} ref={ref} />
      </button>
    )),
  };

  const defaultValues = {
    id: null,
    formula: null,
    umbral: null,
    descripcion: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let dataEnviar = {
      estado: 1,
      formula: data.formula,
      umbral: data.umbral.label,
      descripcion_umbral: data.descripcion,
      variables: dataGrid,
    };

    let response;


    try {
      if (!!isEditing) {
        dataEnviar.idindicador = data.id;
        dataEnviar.estado = data.estado.value;

        response = await axios.put(
          process.env.REACT_APP_API_URL + "/indicadores/" + data.id,
          dataEnviar,
          config
        );
      } else {

        response = await axios.post(
          process.env.REACT_APP_API_URL + "/indicadores/",
          dataEnviar,
          config
        );
      }
    } catch (error) {
      console.error(error);
    }

    if (response.status >= 200 && response.status < 300) {
      alert("Guardado con éxito");

      props.dataIndicadores.splice(0, 1, response.data);
      reset();
      props.onHide();
    } else if (response.status >= 300 && response.status < 400) {
      setEstadoPost(4);
    } else if (response.status >= 400 && response.status < 512) {
      setEstadoPost(5);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    if (Array.isArray(props.dataIndicadores)) {
      if (props.selected[0]) {
        axios
          .get(process.env.REACT_APP_API_URL + "/indicadores/" + props.selected[0], {
            headers: {
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          })
          .then((response) => {
            setIsEditing(true);

            setDataGrid(response.data.variables);

            setValue("id", response.data.idindicador);

            setID(response.data.idindicador);

            setDataGrid(response.data.variables);

            setValue("formula", response.data.formula);

            setValue("umbral", {
              value: response.data.umbral,
              label: response.data.umbral,
            });

            setValue("descripcion", response.data.descripcion_umbral);

            if (response.data.estado == 1) {
              setValue("estado", {
                value: response.data.estado,
                label: "Activo",
              });
            } else {
              setValue("estado", {
                value: response.data.estado,
                label: "Inactivo",
              });
            }
          });
      } else {
        reset();
      }
    }
  }, [props.selected]);

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Indicadores de probabilidad
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {!!isEditing ? (
              <Row className="mb-4">
                <Col sm={2} xs={12}>
                  <label className="forn-label label">ID Indicador</label>
                </Col>
                <Col sm={4} xs={12}>
                  <input
                    {...register("id")}
                    disabled
                    type="text"
                    className="form-control text-center texto"
                    placeholder="ID"
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="mb-4">
              <Col sm={12} xs={12}>
                <MaterialTable
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                    paging: false,
                    sorting: false,
                    draggable: false,
                    headerStyle: {
                      backgroundColor: "#2c2a29",
                      color: "#FFF",
                    },
                    rowStyle: {
                      backgroundColor: "#f4f4f4",
                    },
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: "No hay datos por mostrar",
                      addTooltip: "Añadir",
                      deleteTooltip: "Eliminar",
                      editTooltip: "Editar",
                      filterRow: {
                        filterTooltip: "Filtrar",
                      },
                      editRow: {
                        deleteText: "¿Segura(o) que quiere eliminar?",
                        cancelTooltip: "Cancelar",
                        saveTooltip: "Guardar",
                      },
                    },
                  }}
                  icons={tableIcons}
                  title=""
                  columns={[
                    {
                      title: "Descripción",
                      field: "descripcion",
                    },
                  ]}
                  data={dataGrid}
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          setDataGrid([...dataGrid, newData]);

                          resolve();
                        }, 1000);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...dataGrid];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;
                          setDataGrid([...dataUpdate]);
                          resolve();
                        }, 1000);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataDelete = [...dataGrid];
                          const index = oldData.tableData.id;
                          dataDelete.splice(index, 1);
                          setDataGrid([...dataDelete]);
                          resolve();
                        }, 1000);
                      }),
                  }}
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Fórmula</label>
              </Col>
              <Col sm={10} xs={12}>
                <input
                  {...register("formula", {
                    required: "Te faltó completar este campo",
                  })}
                  type="text"
                  className="form-control text-center texto"
                  placeholder="Fórmula"
                />
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Umbral</label>
              </Col>
              <Col sm={2} xs={12}>
                <Controller
                  control={control}
                  name="umbral"
                  rules={{ required: "Te faltó completar este campo" }}
                  render={({ field }) => (
                    <Select
                      components={animatedComponents}
                      placeholder="Umbral"
                      options={[
                        { value: 1, label: "Si" },
                        { value: 2, label: "No" },
                      ]}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        if (e.label === "No") {
                          setFlagUmbral(true);
                        } else {
                          setFlagUmbral(false);
                        }
                      }}
                    />
                  )}
                />
                <p className="text-center">{errors.umbral?.message}</p>
              </Col>

              {!flagUmbral ? (
                <>
                  <Col sm={2} xs={12}>
                    <label className="forn-label label">Descripción</label>
                  </Col>
                  <Col sm={6} xs={12}>
                    <textarea
                      {...register("descripcion")}
                      rows={"3"}
                      className="form-control text-center texto"
                      placeholder="Descripción"
                    />
                  </Col>
                </>
              ) : null}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonPositivo"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Guardar
          </Button>
          <Button className="botonNegativo" onClick={props.onHide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}
