import { useContext, useState } from 'react';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import { useLocalStorage } from './useLocalStorage';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const useModifyEvaluation = () => {
  const [localSelected, _] = useLocalStorage('Selected');
  const { saveId, setInfoEvaluation, localRisk } = useContext(RecuperacionesContext)
  const [loadingIdEvaluation, setLoadingIdEvaluation] = useState(false);
  const [errorIdEvaluation, setErrorIdEvaluation] = useState(false);

  const handleModifyIdEvaluation = async () => {
    try {
      setErrorIdEvaluation(null);
      setLoadingIdEvaluation(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL
        }/asistentero/modificar_evaluacion/recibir_id_evaluacion/${saveId}/${localSelected || localRisk[0].idElemento
        }/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json ;charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
      const data = await response.json();
      setInfoEvaluation(data);
    } catch (error) {
      setErrorIdEvaluation(true);
    } finally {
      setLoadingIdEvaluation(false);
    }
  };

  return {
    handleModifyIdEvaluation,
    loadingIdEvaluation,
    errorIdEvaluation,
  };
};
