import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import TableCustom from "./TableCustom";

export default function ModalSelectTableCustom({
  showMod,
  setShowMod,
  data,
  setData,
  dataTable,
  multi,
  idEfecto
}) {
  const [show, setShow] = useState(false);
  const [ddata, setDData] = useState([]);

  useEffect(() => {
    if (showMod === true) {
      setShow(true);
    }

    // if (data.length !== 0){
    //   setDData();
    // };

    if (data.filter((valor) => {return valor.idefecto === idEfecto})[0]){

      let idriesgos = [];


      console.log(data);
      console.log(idEfecto);

      if(data.filter((valor) => {return valor.idefecto === idEfecto})[0].tipo_relacion === "Desencadenado"){
       idriesgos = data.filter((valor) => {
        return valor.idefecto === idEfecto})[0].desencadenado_en.split(",").map((elemento) => {return Number(elemento)})
        ;
      }
      else{

        console.log(data.filter((valor) => {return valor.idefecto === idEfecto})[0].recibido_de.split(",").map((elemento) => {return Number(elemento)}));
        idriesgos = data.filter((valor) => {return valor.idefecto === idEfecto})[0].recibido_de.split(",").map((elemento) => {return Number(elemento)});
      }
      

      // setDData(dataTable.dataTable.filter((ev) => {if(idriesgos.includes(ev.idriesgo)){return ev}}));

      setDData(idriesgos);
    };



  }, [showMod, setShow]);

  const handleClose = () => {
    setShow(false);
    setShowMod(false);
  };

  const retornarSelected = (dataSelected) => {
    let tempSelected = [];
    dataTable.dataTable.map((usrMod) => {
      dataSelected.map((usrSelected) => {
        if (usrSelected === usrMod[dataTable.nameId]) {
          tempSelected.push(usrMod);
        }
      });
    });


    let arr = [];

    // console.log(ddata);


    // ddata.map((aa) => {arr.push(aa.idriesgo)});

    // console.log(arr);

    let mm = ddata.join(",");

    console.log("ALOOOOOO");
    console.log(mm);

    

    if (multi) {
      setData(data.map((dat) => {
        
        if(dat.idefecto === idEfecto){
          
          if(dat.tipoEfecto){
            dat.tipo_relacion = dat.tipoEfecto;
            dat.tipo = dat.tipoefecto;
          }

        if(dat.tipo_relacion === "Desencadenado"){
          dat["desencadenado_en"] = mm
        }
        else{
          dat["recibido_de"] = mm
        }
      
      }; 
        return dat}));

    } else {
      setData(data.map((dat) => {
        if(dat.idefecto === idEfecto){
        if(dat.tipo_relacion === "Desencadenado"){
          dat["desencadenado_en"] = mm;
        }
        if(dat.tipo_relacion === "Recibido"){
          dat["recibido_de"] = mm;
        }
      
      }; 
        return dat}));
    }
    
    console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNN");
    console.log(data);
  };

  return (
    <>
      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Seleccionar elemento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dataTable ? (
            <TableCustom
              data={dataTable.dataTable}
              nameCol={dataTable.nameCol}
              nameRow={dataTable.nameRow}
              nameId={dataTable.nameId}
              multi={multi}
              busqueda={dataTable.busqueda}
              nameBusqueda={dataTable.nameBusqueda}
              selectedData={ddata}
              setSelectedData={setDData}
            />
          ) : (
            <p> Debe de incluir los datos para mostrar en la tabla </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonPositivo"
            onClick={() => {
              handleClose();
              retornarSelected(data);
            }}
          >
            Aceptar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <span></span>
    </>
  );
}
