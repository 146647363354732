import React, { useState, useEffect, useContext } from "react";

import { Button, Row, Col, Form, Container, Modal } from "react-bootstrap";

import AADService from "../../../auth/authFunctions";

import axios from "axios";
import Loader from "react-loader-spinner";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import { elementType } from "prop-types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function ModalAsociarMacroriesgos(props) {
  const serviceAAD = new AADService();
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const [loadingData, setLoadingData] = React.useState(false);
  const [buscando, setBuscando] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "/causas/", {
          headers: {
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        })
        .then((response) => {
          let data = response.data;

          let filteredlist = data.filter(
            (element) =>
              !props.dataMacroriesgos.some(
                (item) => item.idcausa === element.idcausa
              )
          );

          setData(filteredlist);
          setDataBusqueda(filteredlist);
        });
    } catch (error) {
      console.error(error);
    }
  }, [props.show]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
    } else {
    }
    setSelected(newSelected);
  };

  async function buscar(e) {
    e.persist();

    setBuscando(e.target.value);

    var search = data.filter((item) => {
      if (
        String(item.idcausa)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.compania)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.nombre)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.arista_causa)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.factor).toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    //await setBuscando(e.target.value);
    //await setDataBusqueda(search);
    await setDatosBusqueda(e.target.value, search);
  }

  const setDatosBusqueda = async (buscando, busqueda) => {
    setBuscando(buscando);
    setDataBusqueda(busqueda);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Asociar = () => {
    
    if (selected[0]) {
      const selectedData = data.filter(
        (macroriesgo) => macroriesgo.idcausa == selected[0]
      );

      if (selectedData[0].estado === 0) {
        alert("No se pueden asociar elementos inactivos");
      } else {
        props.dataMacroriesgos.splice(0, 1, selectedData[0]);
      }

      setSelected([]);
      props.onHide();
    } else {
    }
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Col sm={8} xs={12}>
          <Modal.Title id="contained-modal-title-vcenter">
            Causas LAFT
          </Modal.Title>
        </Col>
        <Col sm={2} xs={12}>
          <button type="button" className="btn botonPositivo" onClick={Asociar}>
            Seleccionar
          </button>
        </Col>

        <Col sm={2} xs={12}>
          <button
            type="button"
            className="btn botonNegativo"
            onClick={props.onHide}
          >
            Cancelar
          </button>
        </Col>

      </Modal.Header>
      <Modal.Body className="show-grid">
      <Row className="mb-3 mt-3">
        <Col sm={4} xs={12}>
          <Form>
            <Form.Control
              value={buscando}
              onChange={(e) => buscar(e)}
              type="text"
              placeholder="Buscar"
            />
          </Form>
        </Col>
        </Row>
        <Container fluid>
          <Row className="mb-3 mt-3"></Row>

          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell align="left">ID Causa</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Compañía</StyledTableCell>
                    <StyledTableCell align="left">Factor</StyledTableCell>
                    <StyledTableCell align="left">Aristas</StyledTableCell>
                    <StyledTableCell align="left">Efectividad Grupo de Controles</StyledTableCell>
                    <StyledTableCell align="left">Calificación Impacto</StyledTableCell>
                    <StyledTableCell align="left">Calificación Probabilidad</StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataBusqueda.map((row) => {
                    const isItemSelected = isSelected(row.idcausa);
                    return (
                      <StyledTableRow
                        key={row.idcausa}
                        hover
                        onClick={(event) =>
                          handleClick(event, row.idcausa)
                        }
                        selected={isItemSelected}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={isItemSelected} />
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          {row.idcausa ? row.idcausa : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.nombre ? row.nombre : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.compania ? row.compania : null}
                        </StyledTableCell>
            
                        <StyledTableCell align="left">
                          {row.factor ? row.factor : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.arista_causa ? row.arista_causa : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.efectividad_grupo_controles ? row.efectividad_grupo_controles : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.calificacion_impacto ? row.calificacion_impacto : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.calificacion_probabilidad ? row.calificacion_probabilidad : null}
                        </StyledTableCell>

                      </StyledTableRow>
                    );
                  })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={dataBusqueda.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
