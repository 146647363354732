import React, { useEffect, useContext } from "react";

export default function Inicio() {
  useEffect(() => {}, []);

  return (
    <>
      <h2>Bienvenidos a la herramienta de GRC</h2>
    </>
  );
}
