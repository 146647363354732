import { useEffect, useState } from 'react';
import axios from "axios";
import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const useAllRisks = () => {
  const [company, setCompany] = useState([]);
  const [newBusinessInfo, setNewBussinesInfo] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/maestros_ro/compania/`,
          {
            headers: {
              'Content-Type': 'application/json; charset-UTF-8',
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          }
        );
        const data = await response.data;
        console.log(data);
        console.log("informacion de la compañias")
        setCompany(data);
      } catch (error) {
        throw new Error(`Error has ocurred in GET ${error}`);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/generales/Evaluaciones/Tipo_elemento_negocio_principal`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json; charset-UTF-8',
              Authorization: "Bearer " + serviceAAD.getToken()
            },
          }
        );
        const data = await response.json();
        setNewBussinesInfo(
          data.filter(
            (item) => item?.valor !== 'Proveedor' && item?.valor !== 'Proyecto'
          )
        );
      } catch (error) {
        throw new Error(`Error has ocurred in GET ${error}`);
      }
    })();
  }, []);

  const getProceso = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ultimonivel/Proceso`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
      return response.json();
    } catch (err) {
      throw new Error(`Error al obtener los datos del proceso ${err}`);
    }
  };

  const getProducto = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ultimonivel/Producto`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
      return response.json();
    } catch (err) {
      throw new Error(`Error al obtener los datos del producto ${err}`);
    }
  };

  const getCanal = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ultimonivel/Canal`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
      return response.json();
    } catch (err) {
      throw new Error(`Error al obtener los datos del canal ${err}`);
    }
  };

  return {
    company,
    newBusinessInfo,
    getProceso,
    getCanal,
    getProducto,
  };
};
