import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

export default function ModalSeguimiento(props) {
  const serviceAAD = new AADService();
  const [listaVariables, setListaVariables] = useState([]);

  const { getValues } = useFormContext();

  const defaultValues = {
    id: null,
    estado: null,
    variable1: null,
    variable2: null,
    valor1: null,
    valor2: null,
    valor: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let fecha = Date.now();

    let hoy = new Date(fecha);

    let arrayVariables = [];

    if (data.variable1) {
      arrayVariables.push(data.variable1.value);
    }
    if (data.variable2) {
      arrayVariables.push(data.variable2.value);
    }

    //Si valor1, valor2 y valor tienen "," se reemplazan por "."
    if (data.valor1) {
      data.valor1 = data.valor1.replace(",", ".");
    }
    if (data.valor2) {
      data.valor2 = data.valor2.replace(",", ".");
    }
    if (data.valor) {
      data.valor = data.valor.replace(",", ".");
    }
    
    let dataEnviar = {
      periodo: hoy.toISOString(),
      variable_1: parseFloat(data.valor1),
      variable_2: parseFloat(data.valor2),
      valor_indicador: parseFloat(data.valor),
      idvariables: arrayVariables,
    };


    try {
      if (!!props.isEditing) {
        dataEnviar.idseguimiento_indicador = data.id;
        //ataEnviar.estado = data.estado.value;
        axios
          .put(
            process.env.REACT_APP_API_URL + "/seguimiento_indicador/" + data.id,
            dataEnviar,
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");

              let data = response.data;

              // let var1 = props.dataVariables.filter(
              //   (item) => item.idvariable === data.variable_1
              // );

              // let var2 = props.dataVariables.filter(
              //   (item) => item.idvariable === data.variable_2
              // );

              // data.variable_1 = var1[0].nombre;

              // data.variable_2 = var2[0].nombre;

              let index = props.data.findIndex(
                (item) =>
                  item.idseguimiento_indicador === data.idseguimiento_indicador
              );

              props.data.splice(index, 1, data);
              reset();
              props.onHide();
            } else if (response.status >= 300 && response.status < 400) {
              //setEstadoPost(4);
            } else if (response.status >= 400 && response.status < 512) {
              //setEstadoPost(5);
            }
          }).catch((error) => {
            alert("Error al guardar\n Algunos campos no son válidos por su longitud");
            console.error(error);
          });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "/seguimiento_indicador",
            dataEnviar,
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");

              let data = response.data;

              // let var1 = props.dataVariables.filter(
              //   (item) => item.idvariable === data.variable_1
              // );

              // let var2 = props.dataVariables.filter(
              //   (item) => item.idvariable === data.variable_2
              // );

              // data.variable_1 = var1[0].nombre;

              // data.variable_2 = var2[0].nombre;

              props.data.push(data);
              reset();
              props.onHide();
            } else if (response.status >= 300 && response.status < 400) {
              //setEstadoPost(4);
            } else if (response.status >= 400 && response.status < 512) {
              //setEstadoPost(5);
            }
          }).catch((error) => {
            alert("Error al guardar\n Algunos campos no son válidos por su longitud");
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    if (props.dataVariables.length > 0) {
      let dataVariablesMap = props.dataVariables;

      let listVariables = dataVariablesMap.map(
        ({
          idvariable: value,
          nombre: label,
          descripcion,
          responsable,
          compania,
        }) => ({ value, label, descripcion, responsable, compania })
      );
      setListaVariables(listVariables);
    } else if (!!props.show && props.dataVariables.length === 0) {
      alert("No hay variables asociadas al indicador");
      props.onHide();
    }
  }, [props.show]);

  useEffect(() => {
    if (Array.isArray(props.data)) {
      if (props.selected[0] && !!props.isEditing) {
        const object = props.data.filter(
          (obj) => obj.idseguimiento_indicador === props.selected[0]
        );

        const dataEditar = object[0];

        const fecha = Date.parse(dataEditar.periodo);

        let var1 = props.dataVariables.filter(
          (item) => item.nombre === dataEditar.variable_1
        );

        let var2 = props.dataVariables.filter(
          (item) => item.nombre === dataEditar.variable_2
        );

        let var1Map = var1.map(({ idvariable: value, nombre: label }) => ({
          value,
          label,
        }));

        let var2Map = var2.map(({ idvariable: value, nombre: label }) => ({
          value,
          label,
        }));

        setValue("id", dataEditar.idseguimiento_indicador);
        setValue("periodo", fecha);

        setValue("valor1", parseFloat(dataEditar.variable_1));

        setValue("valor2", parseFloat(dataEditar.variable_2));

        setValue("valor", parseFloat(dataEditar.valor_indicador));
      } else {
        reset();
      }
    }
  }, [props.isEditing]);

  return (
    // <FormProvider {...methods}>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Seguimiento
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {!!props.isEditing ? (
            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">ID</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("id")}
                  disabled
                  type="text"
                  className="form-control text-center texto"
                  placeholder="ID"
                  //value={id}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Variable 1</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="variable1"
                rules={{ required: "Te falto completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Seleccione la variable"
                    options={listaVariables}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.variable1?.message}</p>
            </Col>

            <Col sm={4} xs={12}>
              <input
                {...register("valor1", {
                  required: "Te falto completar este campo",
                })}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                className="form-control text-center texto"
                placeholder="Valor"
              />
              <p className="text-center">{errors.valor1?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Variable 2</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="variable2"
                rules={{ required: "Te falto completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Seleccione la variable"
                    options={listaVariables}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.variable2?.message}</p>
            </Col>

            <Col sm={4} xs={12}>
              <input
                {...register("valor2", {
                  required: "Te falto completar este campo",
                })}
                min="0"
                onWheel={(e) => e.target.blur()}
                type="number"
                className="form-control text-center texto"
                placeholder="Valor"
              />
              <p className="text-center">{errors.valor2?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Periodo</label>
            </Col>
            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name={"periodo"}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    fullsize
                    disabled={true}
                    selected={Date.now()}
                    onChange={onChange}
                    className="form-control"
                  />
                )}
                rules={{
                  required: false,
                }}
              />
              <p className="text-center">{errors.periodo?.message}</p>
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Valor Indicador*</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("valor", {
                  required: "Te falto completar este campo",
                })}
                disabled={false}
                type="number"
                min="0"
                onWheel={(e) => e.target.blur()}
                className="form-control text-center texto"
                placeholder="Valor"
              />
              <p className="text-center">{errors.valor?.message}</p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="botonPositivo"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Guardar
        </Button>
        <Button className="botonNegativo" onClick={props.onHide}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
    //</FormProvider>
  );
}
