import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";

import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { withStyles, makeStyles } from "@material-ui/core/styles";

const animatedComponents = makeAnimated();

export default function ModalCategoriasCausa(props) {
  const serviceAAD = new AADService();

  const [listaConsecuencias, setListaConsecuencias] = useState([]);
  const [listaCategorias, setListaCategorias] = useState([]);
  const [flagListaCategorias, setFlagListaCategorias] = useState(false);

  const [dataGrid, setDataGrid] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [show, setShow] = useState(false);
  const [showAsociar, setShowAsociar] = useState(false);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const DesasociarEscala = () => {
    if (selected[0]) {
      const selectedData = dataGrid.filter(
        (item) => item.idescala !== selected[0]
      );
      setDataGrid(selectedData);
      setSelected([]);
    } else {
    }
  };

  const defaultValues = {
    id: null,
    estado: null,
    nombre: null,
    nivel: null,
    categoria: null,
    consecuencias: null,
    descripcion:
      "Con la posible materialización del riesgo LA/FT/FPADM se podrían generar, ",
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let dataEnviar = {
      estado: 1,
      nombre_categoria: data.nombre ? data.nombre : null,
      nivel: data.nivel ? data.nivel.label : null,
      // escalas: arrayEscalas,
    };

    let consecuencias;

    if (data.nivel.value === 2) {
      consecuencias = data.consecuencias.map(({ label }) => label);
      consecuencias = consecuencias.toString();

      dataEnviar.categoria_n1 = data.categoria ? data.categoria.label : null;
      dataEnviar.consecuencias = data.consecuencias ? consecuencias : null;
    }


    let response;

    try {
      if (!!props.isEditing) {
        dataEnviar.idcategoria_causas = data.id;
        dataEnviar.estado = data.estado.value;
        response = await axios.put(
          process.env.REACT_APP_API_URL + "/categoriacausas/" + data.id,
          dataEnviar,
          config
        );
      } else {
        response = await axios.post(
          process.env.REACT_APP_API_URL + "/categoriacausas",
          dataEnviar,
          config
        );
      }
    } catch (error) {
      alert(error.response.data.message);

    }

    if (response.status >= 200 && response.status < 300) {
      alert("Guardado con éxito");

      dataEnviar.idcategoria_causas = response.data.idcategoria_causas;
      let index = props.dataCategorias.findIndex(
        (obj) => obj.idcategoria_causas === data.id
      );
      props.dataCategorias.splice(index, 1, dataEnviar);
      setDataGrid([]);
      reset();
      
      let nivelcategoria = props.dataCategorias[0].nivel;

      if (nivelcategoria === 1) {
        props.setDataCategorias([]);
        alert("Creaste una categoría de nivel 1, pero solo se pueden asociar categorías de nivel 2")
        }
      props.onHide();
    } else if (response.status >= 300 && response.status < 400) {
    } else if (response.status >= 400 && response.status < 512) {
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const FilterConsecuencias = (e) => {
    let intro =
      "Con la posible materialización del riesgo LA/FT/FPADM se podrían generar, ";

    let textArray = e.map((obj) => obj.valor);


    setValue("descripcion", intro + textArray.toString());
  };

  const FiltrarNivel = (e) => {
    if (e.value == 1) {
      setFlagListaCategorias(false);
      setValue("categoria", null);
    } else {
      setFlagListaCategorias(true);
    }
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/categoriacausas", config),
      fetch(process.env.REACT_APP_API_URL + "/generales/Consecuencias", config),
    ];

    Promise.all(APIS)
      .then(async ([categorias, consecuencias]) => {
        const listCategorias = await categorias.json();

        let listaCategorias = listCategorias.map(
          ({ idcategoria_causas: value, nombre_categoria: label, nivel }) => ({
            value,
            label,
            nivel,
          })
        );

        let listaCategoriasN1 = listaCategorias.filter(
          (item) => item.nivel === 1
        );
        setListaCategorias(listaCategoriasN1);

        const listConsecuencias = await consecuencias.json();

        let listaConsecuencias = listConsecuencias.map(
          ({ idm_parametrosgenerales: value, parametro: label, valor }) => ({
            value,
            label,
            valor,
          })
        );

        setListaConsecuencias(listaConsecuencias);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.show]);

  useEffect(() => {
    if (Array.isArray(props.dataCategorias)) {
      if (props.selected[0] && props.isEditing) {
        axios
          .get(process.env.REACT_APP_API_URL + "/categoriacausas/" + props.selected[0], {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          })
          .then((response) => {
            let datos = response.data[0];

            setValue("id", datos.idcategoria_causas);
            setValue("nombre", datos.nombre_categoria);

            //------------------------------------------------Estado--------------------

            if (datos.estado == 1) {
              setValue("estado", { value: datos.estado, label: "Activo" });
            } else {
              setValue("estado", { value: datos.estado, label: "Inactivo" });
            }

            //-------------------------------------------Nivel------------------------

            if (datos.nivel === 2) {
              setFlagListaCategorias(true);
            } else {
              setFlagListaCategorias(false);
            }

            setValue("nivel", { value: datos.nivel, label: datos.nivel });

            //-------------------------------------------CategoriaN1--------------------

            setValue("categoria", {
              value: datos.categoria_n1,
              label: datos.categoria_n1,
            });
            //------------------------------------------Consecuencias-------------------

            let stringArrayConseuencias = datos.consecuencias.split(",");

            let initalValuesConsecuencias = [];

            stringArrayConseuencias.forEach((item) => {
              listaConsecuencias.forEach((obj) => {
                if (obj.label === item) {
                  initalValuesConsecuencias.push(obj);
                }
              });
            });

            setValue("consecuencias", initalValuesConsecuencias);

            //---------------------------------Descripción consecuencias-------------------

            FilterConsecuencias(initalValuesConsecuencias);

            //--------------------------------Escalas de probabilidad por compañia------

            setDataGrid(datos.escalas);
          })
          .catch((error) => console.log(error));
      } else {
        reset();
        setDataGrid([]);
      }
    }
  }, [props.isEditing]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Categoria de la Causa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {/* -------------------------------------Modales--------------------- */}

          {/* ------------------------------------------------------- */}
          {!!props.isEditing ? (
            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">ID Categoría</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("id")}
                  disabled
                  type="text"
                  className="form-control text-center texto"
                  placeholder="ID"
                />
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Estado</label>
              </Col>
              <Col sm={4} xs={12}>
                <Controller
                  control={control}
                  name="estado"
                  render={({ field }) => (
                    <Select
                      components={animatedComponents}
                      placeholder="Estado"
                      options={[
                        { value: 1, label: "Activo" },
                        { value: 0, label: "Inactivo" },
                      ]}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Nombre Categoría*</label>
            </Col>
            <Col sm={10} xs={12}>
              <input
                {...register("nombre", {
                  required: "Te faltó completar este campo",
                })}
                type="text"
                className="form-control text-center texto"
                placeholder="Nombre Categoría"
              />
              <p className="text-center">{errors.nombre?.message}</p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Nivel*</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="nivel"
                rules={{ required: "Te faltó completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Nivel"
                    options={[
                      { value: 1, label: 1 },
                      { value: 2, label: 2 },
                    ]}
                    value={field.value}
                    defaultValue={{ value: 1, label: 1 }}
                    onChange={(e) => {
                      field.onChange(e);
                      FiltrarNivel(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.nivel?.message}</p>
            </Col>

            {!!flagListaCategorias ? (
              <>
                <Col sm={2} xs={12}>
                  <label className="forn-label label">Categoría N1</label>
                </Col>
                <Col sm={4} xs={12}>
                  <Controller
                    control={control}
                    name="categoria"
                    rules={{
                      required: flagListaCategorias
                        ? "Te faltó completar este campo"
                        : false,
                    }}
                    render={({ field }) => (
                      <Select
                        components={animatedComponents}
                        placeholder="Categoría N1"
                        options={listaCategorias}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <p className="text-center">{errors.categoria?.message}</p>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
          {!!flagListaCategorias ? (
            <>
              <Row className="mb-4">
                <Col sm={2} xs={12}>
                  <label className="forn-label label">Consecuencias*</label>
                </Col>
                <Col sm={10} xs={12}>
                  <Controller
                    control={control}
                    name="consecuencias"
                    rules={{ required: "Te faltó completar este campo" }}
                    render={({ field }) => (
                      <Select
                        isMulti
                        components={animatedComponents}
                        placeholder="Consecuencias"
                        options={listaConsecuencias}
                        value={field.value}
                        onChange={(e) => {
                          FilterConsecuencias(e);

                          const unique = new Set();

                          const filteredList = e.filter((element) => {
                            const isDuplicate = unique.has(element.label);

                            unique.add(element.label);

                            if (!isDuplicate) {
                              return true;
                            } else {
                              return false;
                            }
                          });

                          field.onChange(filteredList);
                        }}
                      />
                    )}
                  />
                  <p className="text-center">{errors.consecuencias?.message}</p>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col sm={2} xs={12}>
                  <label className="forn-label label">
                    Descripción Consecuencias
                  </label>
                </Col>
                <Col sm={10} xs={12}>
                  <textarea
                    {...register("descripcion")}
                    rows={"4"}
                    className="form-control text-center texto"
                    placeholder="Descripción"
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="botonPositivo"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Guardar
        </Button>
        <Button
          className="botonNegativo"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
