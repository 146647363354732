import { useForm } from 'react-hook-form';
import { useCreateRisk } from '../hooks/useCreateRisk';
import { CircularProgress } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAllRisks } from '../hooks/useAllRisks';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import InfoIcon from '@mui/icons-material/Info';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import '../index.css';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {Col} from "react-bootstrap";
import { iteratee } from 'lodash';

const animatedComponents = makeAnimated();

export default function IdentificadorRiesgos() {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const { receivedInfoDocument } = useContext(RecuperacionesContext);
  const { company, newBusinessInfo, getProceso, getProducto, getCanal } =
    useAllRisks();
  const {
    valueInputs,
    handleChange,
    OnCreateRisk,
    isLoading,
    nameElement,
    selectedCompany,
    selectedBusiness,
    handleInitiatives,
    setNameElement,
    setSelectedCompany,
    setSelectedBusiness,
  } = useCreateRisk();

  const [allElements, setAllElements] = useState([]);
  const [allElementLoading, setAllElementsLoading] = useState(false);
  const [allElementError, setAllElementsError] = useState(null);
  const router = useHistory();
  const [elementsList, setElementsList] = useState(null);

  function testFunction(e) {
    setSelectedBusiness(e.target.value);
    onchangeelement(e.target.value);
}


  useEffect(() => {
    (async () => {
      try {
        setAllElementsLoading(true);
        setAllElementsError(null);
        const response = await Promise.all([
          getProceso(),
          getProducto(),
          getCanal(),
        ]);
        const data = response;
        setAllElements(data);
      } catch (error) {
        setAllElementsError(true);
      } finally {
        setAllElementsLoading(false);
      }
    })();
  }, []);

  const handleSelectCompany = useCallback((e) => {
    setSelectedCompany(e.target.value);
  }, []);


  const onchangeelement = (e) => {

    switch(e) {
      case "Proceso":
      // code block
      setElementsList(
        allElements[0]
        ?.filter(
          (item) =>
            item.idcompania === Number(selectedCompany)
        )
        ?.map((item) => (
          {value: item.id , label: item.nombre}
        )))
    
      break;

      case "Canal":

      setElementsList(
        allElements[2]
        ?.filter(
          (item) =>
            item.idcompania === Number(selectedCompany)
        )
        ?.map((item) => (
          {value: item.id , label: item.nombre}
        )))

      // code block
      
      break;

      case "Producto":

      setElementsList(
        allElements[1]
        ?.filter(
          (item) =>
            item.idcompania === Number(selectedCompany)
        )
        ?.map((item) => (
          {value: item.id , label: item.nombre}
        )))

      break;

      case "Otras iniciativas":
          setNameElement({label: null});
      break;

      default:
        console.log("error")
      }      

  };


  const OnSubmit = async () => {
    await OnCreateRisk();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1
          className="titulo_resumen"
          style={{ fontSize: '2.25rem', lineHeight: '2.5rem', padding: '5rem' }}
        >
          Información del elemento que quieres analizar
        </h1>
      </div>

      {isLoading || allElementLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit(OnSubmit)}
              onClick={() =>
                receivedInfoDocument(selectedBusiness, selectedCompany)
              }
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <h3 className="titulo_resumen">Compañia*</h3>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <label htmlFor="company">
                      {selectedCompany === undefined ? (
                        errors?.company ? (
                          <p style={{ color: 'red' }}>
                            <InfoIcon /> {errors?.company?.message}
                          </p>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </label>
                    <select
                      {...register('company', {
                        required: 'Este campo es requerido',
                      })}
                      style={{
                        marginBottom: '5rem',
                        borderRadius: '0.5rem',
                        padding: '0.7rem',
                        borderStyle: 'solid',
                      }}
                      value={selectedCompany}
                      onChange={handleSelectCompany}
                    >
                      <option disabled selected></option>
                      {company.map((company, index) => (
                        <option key={index} value={company?.idcompania}>
                          {company?.compania}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <h3 className="titulo_resumen">
                    Elemento del negocio principal evaluado*
                  </h3>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '1rem',
                    }}
                  >
                    <label htmlFor="business">
                      {selectedBusiness === undefined ? (
                        errors?.business ? (
                          <p style={{ color: 'red' }}>
                            <InfoIcon /> {errors?.business?.message}
                          </p>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </label>
                    <select
                      {...register('business', {
                        required: 'Este campo es requerido',
                      })}
                      style={{
                        marginBottom: '5rem',
                        borderRadius: '0.5rem',
                        padding: '0.7rem',
                        borderStyle: 'solid',
                      }}
                      onChange={(e) => {testFunction(e)}}
                    >
                      <option disabled selected></option>
                      {newBusinessInfo.map((business, index) => (
                        <option key={index} value={business?.nombre}>
                          {business?.valor}
                        </option>
                      ))}
                    </select>

                    {selectedBusiness === 'Otras iniciativas' ? (
                      <span
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <input
                          {...register('iniciativas', {
                            required: 'Este campo es requerido',
                          })}
                          className="form-control text-left texto"
                          placeholder="Otras iniciativas"
                          onChange={handleInitiatives}
                        />
                        {errors?.iniciativas && (
                          <p style={{ color: 'red' }}>
                            <InfoIcon /> {errors?.iniciativas?.message}
                          </p>
                        )}
                      </span>
                    ) : (
                      <Col xs={12}>
                      <Select
                      id={"elemento"}
                      components={animatedComponents}
                      value={nameElement}
                      options={elementsList}
                      placeholder={"Seleccione el elemento ppal"}
                      onChange={(e) => {setNameElement(e)}}
                    />
                    </Col>

                    )}
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      style={{
                        fontSize: '1.125rem',
                        lineHeight: '1.75rem',
                        fontWeight: 700,
                      }}
                    >
                      Objetivo de tu elemento:*
                    </label>
                    <textarea
                      {...register('objetivo', {
                        required: 'Este campo es requerido',
                      })}
                      value={valueInputs.objetivo}
                      style={{
                        borderRadius: '0.5rem',
                        borderWidth: '2px',
                        padding: '0.5rem',
                      }}
                      type="text"
                      aria-invalid={errors.objetivo ? 'true' : 'false'}
                      onChange={handleChange}
                      placeholder="Redacta en pocas palabras el objetivo del elemento a analizar​"
                      name="objetivo"
                      rows={10}
                      cols={60}
                    />
                    {errors?.objetivo && (
                      <p style={{ color: 'red' }}>
                        <InfoIcon />
                        {errors?.objetivo?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      style={{
                        fontSize: '1.125rem',
                        lineHeight: '1.75rem',
                        fontWeight: 700,
                      }}
                    >
                      Descripcion de tu elemento:*
                    </label>

                    <textarea
                      {...register('insumo', {
                        required: 'Este campo es requerido',
                      })}
                      style={{
                        borderRadius: '0.5rem',
                        borderWidth: '2px',
                        padding: '0.5rem',
                      }}
                      type="text"
                      placeholder="Redacta de manera detallada la descripción del elemento a analizar"
                      aria-invalid={errors.insumo ? 'true' : 'false'}
                      onChange={handleChange}
                      name="insumo"
                      rows={10}
                      cols={60}
                    />
                    {errors?.insumo && (
                      <p style={{ color: 'red' }}>
                        <InfoIcon />
                        {errors?.insumo?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '5rem',
                  gap: '12rem',
                }}
              >
                {selectedCompany === undefined ||
                  selectedBusiness === undefined ? (
                  ''
                ) : (
                  <button
                    type="submit"
                    className="botonPositivo btn btn-primary"
                  >
                    Encontremos los riesgos operacionales
                  </button>
                )}

                {/* {selectedCompany === undefined ||
                  selectedBusiness === undefined ? (
                  ''
                ) : (
                  <button
                    type="button"
                    onClick={() => router?.push('/ElementoAnalizar')}
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#4B5563',
                    }}
                  >
                    <UploadFileIcon style={{ width: '5rem', height: '5rem' }} />
                  </button>
                )} */}
              </div>
            </form>
          </section>
        </>
      )}
    </>
  );
}
