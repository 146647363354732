import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";

import { FormSearchListCompania } from "../../../form-components/FormSearchListCompania";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function ModalVariables(props) {
  const serviceAAD = new AADService();

  const [options, setOptions] = useState([]);

  const defaultValues = {
    id: null,
    nombre: null,
    descripcion: null,
    responsable: null,
    compania: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let dataEnviar = {
      nombre: data.nombre ? data.nombre : null,
      descripcion: data.descripcion ? data.descripcion : null,
      responsable: data.responsable ? data.responsable.label : null,
      compania: data.compania ? data.compania.label : null,
    };


    try {
      if (!!props.isEditing) {
        dataEnviar.idvariable = data.id;

        axios
          .put(
            process.env.REACT_APP_API_URL + "/variables/" + data.id,
            dataEnviar,
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");
              let data = response.data;

              let index = props.data.findIndex(
                (item) => item.idvariable === data.idvariable
              );
              props.data.splice(index, 1, response.data);
              reset();
              props.onHide();
            } else if (response.status >= 300 && response.status < 400) {
            } else if (response.status >= 400 && response.status < 512) {
            }
          }).catch((error) => {
            alert("Error al guardar\n Algunos campos no son válidos por su longitud");
            console.error(error);
          });
      } else {
        axios
          .post(
            process.env.REACT_APP_API_URL + "/variables",
            dataEnviar,
            config
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Guardado con éxito");
              props.data.push(response.data);
              reset();
              props.onHide();
            } else if (response.status >= 300 && response.status < 400) {
            } else if (response.status >= 400 && response.status < 512) {
            }
          }).catch((error) => {
            alert("Error al guardar\n Algunos campos no son válidos por su longitud");
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/4", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/2", config),
      fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/1", config),
    ];

    Promise.all(APIS)
      .then(async ([riskAnalyst, riskManager, administrador]) => {
        const rAnalist = await riskAnalyst.json();

        const rManager = await riskManager.json();

        const admin = await administrador.json();

        let listRiskAnalist = rAnalist.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listRiskManager = rManager.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listAdministrador = admin.map(
          ({ idusuario: value, nombre: label, email }) => ({
            value,
            label,
            email,
          })
        );

        let listExpertos = listRiskAnalist.concat(
          listRiskManager,
          listAdministrador
        );

        setOptions(listExpertos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.show]);

  useEffect(() => {
    if (Array.isArray(props.data)) {
      if (props.selected[0] && !!props.isEditing) {
        props.data.forEach((obj) => {
          if (obj.idvariable === props.selected[0]) {
            setValue("id", obj.idvariable);
            setValue("nombre", obj.nombre);
            setValue("descripcion", obj.descripcion);
            setValue("compania", obj.compania);


            let responsable = options.filter(
              (item) => item.label === obj.responsable
            );
            setValue("responsable", responsable[0]);
          }
        });
      } else {
        reset();
      }
    }
  }, [props.isEditing]);

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Variables
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {!!props.isEditing ? (
              <Row className="mb-4">
                <Col sm={2} xs={12}>
                  <label className="forn-label label">ID</label>
                </Col>
                <Col sm={4} xs={12}>
                  <input
                    {...register("id")}
                    disabled
                    type="text"
                    className="form-control text-center texto"
                    placeholder="ID"
                    //value={id}
                  />
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Nombre*</label>
              </Col>
              <Col sm={10} xs={12}>
                <input
                  {...register("nombre")}
                  type="text"
                  className="form-control text-center texto"
                  placeholder="Nombre de la variable"
                />
                <p className="text-center">{errors.nombre?.message}</p>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Descripción*</label>
              </Col>
              <Col sm={10} xs={12}>
                <textarea
                  {...register("descripcion")}
                  className="form-control text-center"
                  placeholder="Descripción"
                  rows="3"
                />
                <p className="text-center">{errors.descripcion?.message}</p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">Compañía*</label>
              </Col>
              <Col sm={4} xs={12}>
                <FormSearchListCompania
                  control={control}
                  name={"compania"}
                  label={"Seleccione la compañia"}
                />
                <p className="text-center">{errors.compania?.message}</p>
              </Col>

              <Col sm={2} xs={12}>
                <label className="forn-label label">Responsable</label>
              </Col>
              <Col sm={4} xs={12}>
                <Controller
                  control={control}
                  name="responsable"
                  rules={{ required: "Te faltó completar este campo" }}
                  render={({ field }) => (
                    <Select
                      components={animatedComponents}
                      placeholder="Seleccione el responsable"
                      options={options}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <p className="text-center">{errors.responsable?.message}</p>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonPositivo"
            onClick={handleSubmit(onSubmit, onError)}
          >
            Guardar
          </Button>
          <Button className="botonNegativo" onClick={props.onHide}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
}
