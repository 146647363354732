import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import { useForm, Controller, FormProvider } from "react-hook-form";
import AADService from "../../auth/authFunctions";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import { IoMdDownload } from "react-icons/io";
import axios from "axios";
import { UsuarioContext } from "../../Context/UsuarioContext";

import Loader from "react-loader-spinner";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function CausasCumplimiento(props) {
  const serviceAAD = new AADService();
  const classes = useStyles();
  let history = useHistory();

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [buscando, setBuscando] = React.useState(null);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const [listaCausas, setListaCausas] = React.useState([]);
  const [dataCompania, setDataCompania] = React.useState([]);
  const [dataFactor, setDataFactor] = React.useState([]);
  const [descarga, setDescarga] = React.useState(true);
  const { dataUsuario } = React.useContext(UsuarioContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Editar = () => {
    if (Array.isArray(selected) && selected.length > 0) {
      history.push({
        pathname: "/EditarCausaCumplimiento",
        state: { idCausa: selected[0] },
      });
    } else {
    }
  };

  async function buscar(e) {
    e.persist();

    //await setBuscando(e.target.value);
    var search = data.filter((item) => {
      if (
        String(item.idcausa)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.nombre)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.compania)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.arista_causa)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.factor).toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    //await setBuscando(e.target.value);
    //await setDataBusqueda(search);
    await setDatosBusqueda(e.target.value, search);
  }
  const setDatosBusqueda = async (buscando, busqueda) => {
    setBuscando(buscando);
    setDataBusqueda(busqueda);
  };

  const defaultValues = {
    compania: null,
    factor: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const Filtrar = () => {
    setData(listaCausas);
    const compania = getValues("compania");
    const factor = getValues("factor");


    var search = listaCausas.filter((item) => {
      if (
        (compania === undefined ||
          compania === null ||
          compania.label === "Todas" ||
          compania.label == item.compania) &&
        (factor === undefined ||
          factor === null ||
          factor.label === "Todas" ||
          factor.label == item.factor)
      ) {
        return item;
      }
    });

    setData(search);
    setDataBusqueda(search);
  };
  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/causas/", config),
      fetch(process.env.REACT_APP_API_URL + "/maestros_ro/compania/", config),
      fetch(process.env.REACT_APP_API_URL + "/generales/Causas/Factor", config),
    ];

    Promise.all(APIS)
      .then(async ([causas, companias, factores]) => {
        const listCausas = await causas.json();

        setListaCausas(listCausas);
        setData(listCausas);
        setDataBusqueda(listCausas);

        const listCompanias = await companias.json();

        let listaCompanias = listCompanias.map(
          ({ idcompania: value, compania: label }) => ({
            value,
            label,
          })
        );

        listaCompanias.unshift({ value: 0, label: "Todas" });

        setDataCompania(listaCompanias);

        const listFactores = await factores.json();

        let listaFactores = listFactores.map(
          ({ idm_parametrosgenerales: value, valor: label }) => ({
            value,
            label,
          })
        );

        listaFactores.unshift({
          value: 0,
          label: "Todas",
        });
        setDataFactor(listaFactores);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoadingData(false);
  }, []);

  async function fileFromURL(url) {
    let urlConsulta = url;
    axios({
      url: urlConsulta,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    })
      .then((response) => {
        let name = urlConsulta.split("/").pop();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        setDescarga(true);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          setEstadoPost({
            id: 3,
            data: "No estas autorizado para realizar esta accion o tu sesión esta vencida",
          });
          setTimeout(() => {
            setEstadoPost({ id: 0, data: null });
          }, 10000);
        } else if (error.response.status == 404) {
          setEstadoPost({
            id: 3,
            data: "No se encontró el archivo seleccionado",
          });
          setTimeout(() => {
            setEstadoPost({ id: 0, data: null });
          }, 10000);
        }
      });
  }

  async function descargaLAFT() {
    setDescarga(false);
    let urlConsulta = process.env.REACT_APP_API_URL + "/informes";
    const data = {
      informe: "informe_LAFT",
      idcompania: dataUsuario.idcompania,
    };
    fetch(urlConsulta, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    })
      .then((response) => {
        response.json().then((data) => {
          let urlFetch = data.URL;

          fileFromURL(urlFetch);
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          setEstadoPost({
            id: 3,
            data: "No estas autorizado para realizar esta accion o tu sesión esta vencida",
          });
          setTimeout(() => {
            setEstadoPost({ id: 0, data: null });
          }, 10000);
        } else if (error.response.status == 404) {
          setEstadoPost({
            id: 3,
            data: "No se encontró el archivo seleccionado",
          });
          setTimeout(() => {
            setEstadoPost({ id: 0, data: null });
          }, 10000);
        }
      });
  }
  return (
    <>
      <Container fluid>
        <Row className="mb-3 mt-3">
          <Col sm={8} xs={12}>
            <h1 className="titulo">Causas Cumplimiento</h1>
          </Col>
        </Row>
        <FormProvider {...methods}>
          <Row className="mb-3 mt-3">
            <Col sm={4} xs={12}>
              <Form>
                <Form.Control
                  value={buscando}
                  onChange={(e) => buscar(e)}
                  type="text"
                  placeholder="Buscar"
                />
              </Form>
            </Col>

            <Col
              style={{ paddingTop: "0.3%" }}
              className="d-flex justify-content-end"
              sm={4}
              xs={12}
            >
              {props.permisos.descargar ?     
              ( descarga ? (
                <Button
                  className="botonPositivo"
                  onClick={() => descargaLAFT()}
                >
                  Descargar masiva &nbsp; <IoMdDownload />
                </Button>
              ) : (
                <Col className="col-auto" sm={3} xs={3}>
                  <Loader
                    type="Oval"
                    color="#FFBF00"
                    height={30}
                    width={30}
                    style={{
                      textAlign: "center",
                      position: "static",
                    }}
                  />
                </Col>
              ))
        
              : null} 
            </Col>
            
            <Col sm={2} xs={12}>
              {selected[0] ? (
                <Button className="botonNegativo" onClick={Editar}>
                  Editar
                </Button>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={2} xs={12}>
              <Link to="CrearCausaCumplimiento">
                <Button className="botonPositivo">Crear</Button>
              </Link>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name={"compania"}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    components={animatedComponents}
                    options={dataCompania}
                    onChange={(e) => {
                      onChange(e);
                      Filtrar();
                    }}
                    value={value}
                    placeholder="Compañia"
                  />
                )}
              />
            </Col>
            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name={"factor"}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    components={animatedComponents}
                    options={dataFactor}
                    onChange={(e) => {
                      onChange(e);
                      Filtrar();
                    }}
                    value={value}
                    placeholder="Factor"
                  />
                )}
              />
            </Col>
          </Row>

          {loadingData ? (
            <Row className="mb-3 mt-5">
              <Col>
                <Loader
                  type="Oval"
                  color="#FFBF00"
                  style={{ textAlign: "center", position: "static" }}
                />
              </Col>
            </Row>
          ) : (
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table className={"text"} aria-label="sticky table">
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>
                      <StyledTableCell>ID Causa</StyledTableCell>

                      <StyledTableCell align="left">Compañia</StyledTableCell>

                      <StyledTableCell align="left">Factor</StyledTableCell>

                      <StyledTableCell align="left">Causa</StyledTableCell>

                      <StyledTableCell align="left">Aristas</StyledTableCell>

                      <StyledTableCell align="left">Estado</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataBusqueda
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idcausa);
                        return (
                          <StyledTableRow
                            key={row.idcausa}
                            hover
                            onClick={(event) => handleClick(event, row.idcausa)}
                            selected={isItemSelected}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Checkbox checked={isItemSelected} />
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              {row.idcausa ? row.idcausa : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.compania ? row.compania : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.factor ? row.factor : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.nombre !== null ? row.nombre : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.arista_causa ? row.arista_causa : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.estado === 1 ? "Activo" : "Inactivo"}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </FormProvider>
      </Container>
    </>
  );
}
