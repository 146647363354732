import { useContext, useEffect, useState } from 'react';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as XLSX from 'xlsx';
import { useLocalStorage } from '../hooks/useLocalStorage';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();
const infoUser = serviceAAD.getUser().userName;

export const CrearIdEvaluacion = () => {
  const {
    setBodyEdit,
    setBodyFound,
    localRisk 
  } = useContext(RecuperacionesContext);
  const [localSelected, _] = useLocalStorage('Selected');
  const [downloadInfo, setDownloadInfo] = useState([]);
  const [newId, setNewId] = useState([]);
  const [loadingId, setLoadingId] = useState(false);
  const router = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setLoadingId(true);
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/asistentero/enviar_ids_riesgos_actualizados/${infoUser}/${
            localSelected || Number(localRisk[0].idElemento)
          }/`,
          {
            headers: {
              'Content-Type': 'application/json; charset-UTF-8',
              Authorization: "Bearer " + serviceAAD.getToken()
            },
          }

        );

        const data = await response.json();
        setNewId(data);
      } catch (error) {
      } finally {
        setLoadingId(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async() => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/asistentero/descargar_informacion/${infoUser}/${
            localSelected || Number(localRisk[0].idElemento)
          }/`,
          {            
            headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
            }
          }
        );
        const data = await response.json();
        setDownloadInfo(data);
      } catch (error) {
        throw new Error(`An error has ocurred ${error}`);
      }
    })()
  }, []);

  function downloadExcelIds() {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(downloadInfo);

    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    const excelBufer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBufer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'info_ids.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      {loadingId ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5rem',
          }}
        >
          <CircularProgress size={30} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div>
            <h1
              className="titulo_resumen"
              style={{ textAlign: 'center', fontSize: '3rem' }}
            >
              ID evaluacion:
            </h1>
            <br />
            {newId.map((index) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <h1
                  className="titulo_resumen"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: '3rem',
                  }}
                >
                  {index.id_evaluacion}
                </h1>
                <br />
                <br />
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <h1
              className="titulo_resumen"
              style={{ textAlign: 'center', fontSize: '3rem' }}
            >
              ID's y Nombres de los elementos:
            </h1>
            {newId.map((index) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignitems: 'center',
                }}
              >
                <h1
                  className="titulo_resumen"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: '2rem',
                  }}
                >
                  {index.id_riesgo}
                </h1>
                <h1
                  className="titulo_resumen"
                  style={{ textAlign: 'center', fontSize: '2rem' }}
                >
                  : {index.nombre_categoria}
                </h1>
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          paddingTop: '1rem',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          onClick={downloadExcelIds}
          type="button"
          className="botonPositivo btn btn-primary"
        >
          Descargar
        </button>
        <button
          onClick={() => {
            router.push('/RiesgosParciales');
            setBodyEdit(null);
            setBodyFound(null);
          }}
          type="button"
          className="botonNegativo3 btn btn-primary"
        >
          Finalizar
        </button>
      </div>
    </>
  );
};
