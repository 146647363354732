import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function ModalInfo(props) {
  const classes = useStyles();
  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            CALIFICACION DE IMPACTO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell>Valor Cuantitativo</StyledTableCell>

                    <StyledTableCell>Valor Cualitativo</StyledTableCell>

                    <StyledTableCell align="left">
                      Descripción en términos de Riesgo Legal
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      Descripción en términos de Riesgo de Contagio
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      Descripción en términos de Riesgo Operacional
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      Descripción en términos de Riesgo Reputacional
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <StyledTableCell align="left">0.00-0.99</StyledTableCell>

                    <StyledTableCell align="left">Bajo</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>Recomendación de autoridad competente.</p>
                      <p>
                        Plan de ajuste valor e implementación de las
                        actividades.
                      </p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>
                        "Vinculado económico" relacionado con temas LAFT que:
                      </p>
                      <p>* Temas LAFT a nivel local</p>
                      <p>* Aparezca en las listas de alerta</p>
                      <p>
                        * El vinculado opera con relacionados en listas de
                        control categoría de alerta
                      </p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>
                        Hechos que incurren en pérdidas no significativas por
                        deficiencias, fallas o inadecuaciones, en el recurso
                        humano, los procesos, la tecnología, la infraestructura
                        o por la ocurrencia de acontecimientos externos.
                      </p>
                      <p>EFECTOS OPERACIONALES ENTRE $0 y 1.000 Millones</p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>INCIDENTE</p>

                      <p>Carácter del evento: Funcional /Experiencia puntual</p>

                      <p>Duración: Puede presentarse entre minutos y horas</p>

                      <p>
                        Duración consecuencias: Posibles menciones en medios y
                        RRSS durante el evento. Impactos se solucionan el mismo
                        día
                      </p>

                      <p>
                        Impacto en la relación: No se generan emociones o
                        acciones más allá del evento
                      </p>

                      <p>
                        Medidas de tratamiento: Restablecimiento de la
                        experiencia. Comunicación focalizada
                      </p>

                      <p>
                        Sensibilidad Social: En la mayoría el tema no presenta
                        sensibilidad por parte de los grupos
                      </p>

                      <p>Monitoreo: Registro en mesa de crisis</p>
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell align="left">1.00-1.99</StyledTableCell>

                    <StyledTableCell align="left">Medio</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>Orden administrativa / </p>
                      <p>
                        Multa Promedio Histórica Sector Financiero Colombia (100
                        -200 millones COP)
                      </p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>
                        "Vinculado económico" relacionado con temas LAFT que:
                      </p>
                      <p>* Temas LAFT a nivel local</p>
                      <p>
                        * Aparezca en las listas de bloqueo definitivo o
                        restrictivo (diferente listas vinculantes)
                      </p>
                      <p>
                        * El vinculado opera con relacionados en listas de
                        control bloqueo restrictivo o definitivo
                      </p>
                      <p>* Tiene prensa negativa a nivel local</p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>
                        Hechos que incurren en pérdidas como por ejemplo
                        ingresos dejados de percibir, sobrecostos y pérdida del
                        recurso por fallas o inadecuaciones, en el recurso
                        humano, los procesos, la tecnología, la infraestructura
                        o por la ocurrencia de acontecimientos externos.
                        Ejemplos:
                      </p>
                      <p>
                        * Cartera castigada de ciertos clientes en listas de
                        control categoría Bloqueo
                      </p>

                      <p>
                        * Ingresos dejados de percibir por desvinculación de
                        ciertos clientes
                      </p>

                      <p>* Sobrecostos relacionados con LAFT</p>

                      <p>EFECTOS OPERACIONALES ENTRE $1000 y 2.500 Millones</p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>CRISIS DE IMAGEN</p>

                      <p>
                        Carácter del evento: Coyuntural / Riesgo o caso especial
                      </p>

                      <p>
                        Duración: Puede presentarse entre horas y semanas
                        (Agravante de carácter sucesivo*)
                      </p>

                      <p>
                        Duración consecuencias: Hasta meses después: Menciones
                        en medios y RRSS. Gestión de impactos tangibles.
                      </p>

                      <p>
                        Impacto en la relación: Molestias o emociones negativas
                        sin afectar las conductas de apoyo.
                      </p>

                      <p>
                        Medidas de tratamiento: Restablecimiento de la
                        experiencia. Comunicación Masiva. Resarcimiento de
                        daños.
                      </p>

                      <p>
                        Sensibilidad Social: Sensibilidad media para los grupos
                        (Fallas tecnológicas)
                      </p>

                      <p>
                        Monitoreo: Medible a través del IRC e indicadores de
                        negocio / estudio de reputación (Pulse y dimensiones)
                      </p>
                    </StyledTableCell>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell align="left">2.00-3.00</StyledTableCell>
                    <StyledTableCell align="left">Alto</StyledTableCell>
                    <StyledTableCell align="left">
                      <p>Sanción o multa por organismo nacional</p>
                      <p>
                        Multa Máxima. Articulo Régimen sancionatorio Valor
                        presente equivalente a aproximadamente (4.000MCOP)
                      </p>
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <p>
                        "Vinculado económico" relacionado con temas LAFT que:
                      </p>
                      <p>* Temas LAFT a nivel internacional</p>
                      <p>
                        * Aparezca en las listas vinculantes y OFAC (ONU -
                        DUQUE)
                      </p>
                      <p>* El vinculado tiene relacionados en listas OFAC</p>
                      <p>* Tiene prensa negativa a nivel internacional</p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>
                        Hechos que incurren en pérdidas como por ejemplo
                        ingresos dejados de percibir, sobrecostos y pérdida del
                        recurso por fallas o inadecuaciones, en el recurso
                        humano, los procesos, la tecnología, la infraestructura
                        o por la ocurrencia de acontecimientos externos.
                        Ejemplos:
                      </p>
                      <p>
                        * OFAC -ONU amenacen con declarar al Grupo Bancolombia
                        como no cooperante
                      </p>

                      <p>
                        * Cartera castigada de clientes por ingresar a listas en
                        categoría OFAC y ONU.
                      </p>

                      <p>
                        * Ingresos dejados de percibir por desvinculación de
                        clientes
                      </p>

                      <p>* Sobrecostos relacionados con LAFT</p>

                      <p>
                        EFECTOS OPERACIONALES ENTRE $2.500 Millones y 6.000
                        Millones
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p>CRISIS DE REPUTACIÓN</p>

                      <p>
                        Carácter del evento: Estructural/Estratégico
                        /Riesgos/Casos especiales
                      </p>

                      <p>
                        Duración: Puede presentarse entre días y meses
                        (Agravante de carácter sucesivo*)
                      </p>

                      <p>
                        Duración consecuencias: Hasta años después: Menciones en
                        medios y RRSS. Gestión de impactos tangibles.
                      </p>

                      <p>
                        Impacto en la relación: Conductas de apoyo: pérdida de
                        confianza, terminación de relación.
                      </p>

                      <p>
                        Medidas de tratamiento: Comunicación Masiva.
                        Resarcimiento de daños. Cambios en modelo de negocio o
                        procesos internos. Concesiones.
                      </p>

                      <p>
                        Sensibilidad Social: Altamente sensible para grupos
                        (Cambio climático, ética e integridad)
                      </p>

                      <p>
                        Monitoreo: Medible a través de indicadores de negocio /
                        estudio de reputación (conductas de apoyo)
                      </p>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="botonNegativo"
            variant="secondary"
            onClick={() => {
              props.onHide();
            }}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <span></span>
    </>
  );
}
