import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import { useForm, Controller, FormProvider } from "react-hook-form";
import AADService from "../../auth/authFunctions";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import Select from "react-select";
import Loader from "react-loader-spinner";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function Indicadores() {
  const serviceAAD = new AADService();
  const classes = useStyles();
  let history = useHistory();

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [buscando, setBuscando] = React.useState(null);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);
  const [companias, setCompanias] = React.useState([]);
  const [listaIndicadores, setListaIndicadores] = React.useState([]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Editar = () => {
    if (Array.isArray(selected) && selected.length > 0) {
      history.push({
        pathname: "/EditarIndicador",
        state: { idindicador: selected[0] },
      });
    } else {
    }
  };

  async function buscar(e) {
    e.persist();

    //await setBuscando(e.target.value);
    var search = data.filter((item) => {
      if (
        String(item.idindicador)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.nombre)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.descripcion)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.compania)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.responsable)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.ubicacion)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.idelemento)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.periodicidad)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    //await setBuscando(e.target.value);
    //await setDataBusqueda(search);
    await setDatosBusqueda(e.target.value, search);
  }
  const setDatosBusqueda = async (buscando, busqueda) => {
    setBuscando(buscando);
    setDataBusqueda(busqueda);
  };

  const defaultValues = {
    compania: null,
    factor: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const Filtrar = () => {
    setData(listaIndicadores);
    const compania = getValues("compania");

    var search = listaIndicadores.filter((item) => {
      if (
        (compania === undefined ||
          compania === null ||
          compania.label === "Todas" ||
          //item.compania puede ser un string de varias compañias separadas por coma, seprar por coma y buscar en el array
          compania.label == item.compania.split(",").find((x) => x === compania.label))
      ) {
        return item;
      }
    });
    setData(search);
    setDataBusqueda(search);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);

      const result = await fetch(
        process.env.REACT_APP_API_URL + "/indicadores/",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        }
      );

      let data = await result.json();

      setData(data);
      setListaIndicadores(data);
      setDataBusqueda(data);
      setLoadingData(false);
    };

    fetchData();

    async function getCompania() {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "/maestros_ro/compania/",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          }
        );

        let companias = await response.json();
        companias = companias.map(
          ({ idcompania: value, compania: label}) => ({
            value,
            label,
          })
        );
        companias.unshift({ value: 0, label: "Todas"});

        setCompanias(companias);


      } catch (error) {
        console.error(error);
      }
    }
    getCompania();

  }, []);

  return (
    <>
      <Container fluid>
        <Row className="mb-3 mt-3">
          <Col sm={8} xs={12}>
            <h1 className="titulo">Indicadores</h1>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col sm={4} xs={12}>
            <Form>
              <Form.Control
                value={buscando}
                onChange={(e) => buscar(e)}
                type="text"
                placeholder="Buscar"
              />
            </Form>
          </Col>

          <Col sm={4} xs={12}></Col>

          <Col sm={2} xs={12}>
            {selected[0] ? (
              <Button className="botonNegativo" onClick={Editar}>
                Editar
              </Button>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={2} xs={12}>
            <Link to="CrearIndicador">
              <Button className="botonPositivo">Crear</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={2} xs={12}>
            <Controller
              control={control}
              name={"compania"}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Select
                  components={animatedComponents}
                  options={companias}
                  onChange={(e) => {
                    onChange(e);
                    Filtrar();
                  }}
                  value={value}
                  placeholder="Compañia"
                />
              )}
            />
          </Col>
        </Row>

        {loadingData ? (
          <Row className="mb-3 mt-5">
            <Col>
              <Loader
                type="Oval"
                color="#FFBF00"
                style={{ textAlign: "center", position: "static" }}
              />
            </Col>
          </Row>
        ) : (
          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>

                    <StyledTableCell align="left">Nombre</StyledTableCell>

                    <StyledTableCell align="left">Descripción</StyledTableCell>

                    <StyledTableCell align="left">Compañia</StyledTableCell>

                    <StyledTableCell align="left">Responsable</StyledTableCell>

                    <StyledTableCell align="left">Ubicación</StyledTableCell>

                    <StyledTableCell align="left">ID Elemento</StyledTableCell>

                    <StyledTableCell align="left">Periodicidad</StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataBusqueda
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.idindicador);
                      return (
                        <StyledTableRow
                          key={row.idindicador}
                          hover
                          onClick={(event) =>
                            handleClick(event, row.idindicador)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row.idindicador ? row.idindicador : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.nombre !== null ? row.nombre : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.descripcion ? row.descripcion : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.compania ? row.compania : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.responsable ? row.responsable : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.ubicacion ? row.ubicacion : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.idelemento ? row.idelemento : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.periodicidad ? row.periodicidad : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        )}
      </Container>
    </>
  );
}
