import React, { useEffect, useContext, useState } from "react";
import { useCreateRisk } from "../hooks/useCreateRisk";
import { CircularProgress } from '@mui/material';

export default function EncontrarRiesgos() {
  const {riskinfo} = useCreateRisk()
  console.log(riskinfo)

  useEffect(() => {}, []);
  return (
    <>
      <h2
      className="titulo_resumen"
      style={{
        fontSize: '3rem',
        textAlign: 'center',
        marginTop: '6rem'
      }}
      >El resultado del análisis puede ser consultado a partir de mañana con el ID:</h2>
      {riskinfo?.id_analisis}
    </>
  );
}


// import { Alert, CircularProgress } from '@mui/material';
// import { Switch } from '@material-ui/core';
// import { Fragment, useContext, useEffect } from 'react';
// import { useInfoRisk } from '../hooks/useInfoRisk';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { useAPICALL } from '../hooks/useAPICall';
// import { useHandlers } from '../hooks/useHandlers';
// import { useLocalStorage } from '../hooks/useLocalStorage';
// import { RecuperacionesContext } from '../Context/RecuperacionesContext';
// import AADService from '../auth/authFunctions';


// export default function EncontrarRiesgos() {
//   const { updatedBodyFound, bodyEdit, bodyFound, updatedNewTextArea } = useContext(RecuperacionesContext)
//   const { loading, error, risks } = useInfoRisk();
//   const { switching, textArea, handleChange, handleSwitch } = useHandlers();
//   const {
//     OnSubmitCancel,
//     loadingCancel,
//     OnEditRiskSubmit,
//     loadingNewRisk,
//     OnNewSubmit,
//     loadingSubmit,
//     OnSaveEvaluation,
//   } = useAPICALL(bodyFound, bodyEdit);
//   const [value, setValue] = useLocalStorage('Texts', textArea);
//   const serviceAAD = new AADService();
//   const infoUsers = serviceAAD.getUser().userName;
//   const router = useHistory();



//   const uniqueIdEvaluation = [
//     ...new Set(risks?.map((risk) => risk?.id_evaluacion)),
//   ];

//   const hiddenMakeEvaluation = risks.every((risk) => switching[risk.id] === 1);

//   const validateId = risks?.some((risk) => risk.id_evaluacion === ' ');

//   const sendBodyFoundRisk = risks?.map((parse) => ({
//     id: parse.id,
//     id_elemento: parse.id_elemento,
//     nombre_elemento: parse.nombre_elemento,
//     objetivo_elemento: parse.objetivo_elemento,
//     id_categoria: parse.id_categoria,
//     id_categoria_grc: parse.id_categoria_grc,
//     id_usuario: parse.id_usuario,
//     nombre_usuario: parse.nombre_usuario,
//     email_usuario: parse.email_usuario,
//     nombre_categoria: parse.nombre_categoria,
//     nombre_categoria_orx: parse.nombre_categoria_orx,
//     redaccion_ia: parse.redaccion_ia,
//     redaccion_analista:
//       textArea === null ? parse.redaccion_ia : textArea[parse.id],
//     activo:
//       switching[parse.id] === undefined
//         ? parse.activo
//         : Number(!switching[parse.id]),
//     fecha_creacion: parse.fecha_creacion,
//     id_riesgo: parse.id_riesgo,
//     id_evaluacion: parse.id_evaluacion,
//     estado_cancelado: parse.estado_cancelado,
//     estado_agregado_analista: parse.estado_agregado_analista,
//   }));
//   useEffect(() => {
//     updatedBodyFound(sendBodyFoundRisk);
//   }, [switching, risks]);


//   return (
//     <>
//       <section style={{ textAlign: 'center' }}>
//         <h1
//           className="titulo_resumen"
//           style={{
//             fontSize: '2.25rem',
//             lineHeight: '2.5rem',
//             textAlign: 'center',
//           }}
//         >
//           Riesgos sugeridos - ID Evaluación
//           {uniqueIdEvaluation?.map((unique) => (
//             <p>{unique}</p>
//           ))}
//           <br />
//         </h1>
//       </section>

//       {risks.map((risk, index) => (
//         <>
//           {loading ? (
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <CircularProgress size={30} />
//             </div>
//           ) : (
//             <Fragment key={index}>
//               <section
//                 style={{
//                   display: 'flex',
//                   flexDirection: 'row',
//                   marginTop: '5rem',
//                   justifyContent: 'center',
//                 }}
//               >
//                 <div
//                   style={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     gap: '1rem',
//                     paddingRight: '1rem',
//                     width: '30%',
//                   }}
//                 >
//                   <Alert
//                     variant="filled"
//                     severity="info"
//                     sx={{ fontSize: '1rem', backgroundColor: 'black' }}
//                   >
//                     {risk?.nombre_categoria}
//                   </Alert>
//                   <div
//                     style={{
//                       display: 'flex',
//                       flexDirection: 'column',
//                       alignItems: 'start',
//                     }}
//                   >
//                     <Switch
//                       checked={!switching[risk?.id] ?? risk?.activo}
//                       onChange={() => handleSwitch(risk?.id)}
//                     ></Switch>
//                     <label>
//                       {switching[risk?.id] ? 'No Aplica' : 'Aplica'}
//                     </label>
//                     <h2 className="titulo_resumen">
//                       ID del Riesgo: {risk?.id_riesgo}
//                     </h2>
//                   </div>
//                 </div>
//                 <div style={{ display: 'flex', flexDirection: 'column' }}>
//                   <textarea
//                     disabled={switching[risk?.id]}
//                     value={
//                       textArea === null
//                         ? risk?.redaccion_ia
//                         : textArea[risk?.id]
//                     }
//                     onChange={(e) => {
//                       handleChange(e, risk?.id);
//                       setValue(textArea);
//                     }}
//                     style={{
//                       borderRadius: '1.5rem',
//                       boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
//                       padding: '0.5rem',
//                       fontSize: '0.875rem',
//                       lineHeight: '1.25rem',
//                     }}
//                     cols={100}
//                     rows={15}
//                   >
//                     {risk?.redaccion_ia}
//                   </textarea>
//                   <div
//                     style={{
//                       display: 'flex',
//                       justifyContent: 'end',
//                       paddingRight: '1rem',
//                     }}
//                   >
//                     <button
//                       onClick={() => {
//                         updatedNewTextArea(
//                           risk?.id,
//                           risk?.redaccion_ia
//                         );
//                         router.push('/EditarRedaccion');
                        
//                       }}
//                       style={{
//                         width: '25%',
//                         background: '#7052ff',
//                         position: 'relative',
//                         bottom: '3rem',
//                       }}
//                       className=" btn btn-secondary"
//                     >
//                       Editar redacción
//                     </button>
//                   </div>
//                 </div>
//               </section>
//             </Fragment>
//           )}
//         </>
//       ))}
//       {error && <p>{error}</p>}

//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'row',
//           gap: '1rem',
//           paddingTop: '3rem',
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}
//       >
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'row',
//             gap: '1rem',
//             paddingRight: '5rem',
//           }}
//         >
//           {loadingCancel ? (
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <CircularProgress size={30} />
//             </div>
//           ) : (
//             <form onClick={OnSubmitCancel}>
//               <button type="button" className="btn botonNegativo">
//                 Cancelar
//               </button>
//             </form>
//           )}
//           {loadingNewRisk ? (
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <CircularProgress size={30} />
//             </div>
//           ) : (
//             <form onSubmit={OnEditRiskSubmit}>
//               <button type="submit" className="btn botonNegativo">
//                 Guardar
//               </button>
//             </form>
//           )}
//         </div>

//         <button
//           onClick={() => router.push('/AñadirRiesgo')}
//           type="button"
//           className="botonPositivo btn btn-primary"
//         >
//           Añadir nuevo riesgo
//         </button>
//         {loadingSubmit ? (
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//             }}
//           >
//             <CircularProgress size={30} />
//           </div>
//         ) : validateId ? (
//           hiddenMakeEvaluation ? (
//             ''
//           ) : (
//             <form onClick={OnNewSubmit}>
//               <button type="button" className="btn botonNegativo3">
//                 Crear nueva evaluación
//               </button>
//             </form>
//           )
//         ) : (
//           ''
//         )}
//         <form onClick={OnSaveEvaluation}>
//           <button type="button" className="btn botonIngreso">
//             Modificar evaluación
//           </button>
//         </form>
//       </div>
//     </>
//   );
// }
